import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderBlack from "../components/SubHeaderBlack"
import Footer from "../components/Footer";
import { useSearchParams } from 'react-router-dom';
import axios from "axios";
import { useQuery } from "react-query";
import SimpleImageSlider from "react-simple-image-slider";
const endpoint = process.env.REACT_APP_GRAPHCMS_ENDPOINT


function Blog(){
    const [searchParams, setSearchParams] = useSearchParams({id:'-1'});
    const id = searchParams.get('id');
    const FILMS_QUERY = `
    {
      post(where: {id: "${id}"}) {
          body
          publishDate
          title
          featuredPhoto {
            url
          }
          photos {
            url
          }
        }
    }
  `;
    const { data, isLoading, error } = useQuery("", () => {
    return fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query: FILMS_QUERY })
    })
        .then((response) => {
        if (response.status >= 400) {
            throw new Error("Error fetching data");
        } else {
            return response.json();
        }
        })
        .then((data) => data.data);
    });
    if (isLoading) return "Loading...";
    if (error) return <pre>{error.message}</pre>;
    return (
        <>
            <Header></Header>
            <SubHeaderBlack title={"活動及資訊"}></SubHeaderBlack>
            <section>
                <div class="max-w-4xl px-4 pt-8 pb-16 mx-auto transform lg:pt-20 sm:px-6 lg:px-8 lg:pb-24">
                    <h1 class="px-3 mx-auto mb-4 text-4xl text-center text-main">{data.post.title}</h1>
                    <h3 class="px-3 pb-6 text-center text-gray-500">
                        {data.post.publishDate}
                    </h3>
                    <hr class="w-11/12 divider" />
                    <div className="flex">
                    <img
                            class="w-1/2 rounded-lg mx-auto"
                            src={data.post.featuredPhoto.url}
                            alt=""
                        />  
                    </div>
                    <div
                        class="max-w-3xl px-4 pt-6 mx-auto space-y-5 prose text-gray-700 transform lg:pt-16 sm:px-6 lg:px-8 prose-primary lg:pb-8"
                    >
                        <pre className="whitespace-pre-wrap mb-10">
                            {data.post.body}
                        </pre>
                        
                    </div>
                    {data.post.photos.length>0?<div className="w-[100%] h-64 lg:h-128">
                    <SimpleImageSlider
                        width={"90%"}
                        height={"inherit"}
                        images={data.post.photos}
                        showBullets={true}
                        showNavs={true}
                        autoPlay={true}
                        autoPlayDelay={2.0}
                    />
                    </div>:""}
                </div>
            </section>
            <Footer></Footer>
        </>
    )
}

export default Blog;

