import React, { useEffect, useState } from 'react';

function ImageSlider({images, cn, timeGap}){
    function showSlides(slideIndex) {
        let i;
        let slides = document.getElementsByClassName(cn);
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";  
        }
        if (slideIndex > slides.length) {slideIndex = 1}    
        slides[slideIndex-1].style.display = "block";  
        setTimeout(function(){showSlides(slideIndex + 1)}, timeGap); // Change image every 2 seconds
      }
    useEffect(()=>{
        let slideIndex = 1;
        showSlides(slideIndex)
    }, [])

    return(
        <>
            {
                images.map((image) =>
                    <img
                    src={image}
                    alt=""
                    class={"flip object-cover object-center w-full h-full " + cn}
                    />
                )
            }
        </>
  )
}

export default ImageSlider