import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderBlack from "../components/SubHeaderBlack"
import Footer from "../components/Footer";
import axios from "axios";
import { useQuery } from "react-query";

const endpoint = process.env.REACT_APP_GRAPHCMS_ENDPOINT;
const FILMS_QUERY = `
  {
    posts(orderBy: publishDate_DESC) {
        title
        publishDate
        photos {
          url
        }
        featuredPhoto {
          url
        }
        body
        id
      }
  }
`;

function Blogs(){
    const { data, isLoading, error } = useQuery("", () => {
    return fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query: FILMS_QUERY })
    })
        .then((response) => {
        if (response.status >= 400) {
            throw new Error("Error fetching data");
        } else {
            return response.json();
        }
        })
        .then((data) => data.data);
    });
    if (isLoading) return "Loading...";
    if (error) return <pre>{error.message}</pre>;
    return (
        <>
            <Header></Header>
            <SubHeaderBlack title={"活動及資訊"}></SubHeaderBlack>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-10 grid-cols-2 lg:grid-cols-3 lg:gap-20 p-4">
                        {data?data["posts"].map((post)=>{
                            return(
                            <div key={post.title}>
                                <div className="cursor-pointer relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                                    <a href={"/blogs/blog?id=" + post.id}>
                                        {post.featuredPhoto?<img
                                        className="object-cover w-full h-32 xl:h-72"
                                        style={{"object-position":"95% 0"}}
                                        src={post.featuredPhoto.url}
                                        />:""}
                                        <div className="flex flex-col px-5 py-2 transition-opacity duration-300 bg-opacity-75">
                                        <p className="mt-2 mb-2 text-lg lg:text-xl font-bold text-main">
                                            {post.title}
                                        </p>
                                        <p className="mt-2 mb-2 text-base lg:text-lg font-bold">
                                            {post.publishDate}
                                        </p>
                                        </div>
                                    </a>
                                    
                                </div>
                                <div>
                            </div>
                            </div>)
                        }):""}
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    )
}

export default Blogs;

