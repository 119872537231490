import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderWhite from "../components/SubHeaderWhite"
import Footer from "../components/Footer";
import {TutorsData} from "../data/TutorsData";
import { LazyLoadImage } from 'react-lazy-load-image-component';
function Tutors(){
    const [tutorsData, setTutorsData] = useState(TutorsData)
    return(
        <>
            <Header></Header>
            <SubHeaderWhite title={"導師"}></SubHeaderWhite>
            <section>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <div>
                        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                        Core Team
                        </p>
                    </div>
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-main sm:text-4xl md:mx-auto">
                        卡式老師團隊
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                        豐富演出、教育經驗及專業資格認可的導師
                    </p>
                </div>
                <div className="grid gap-10 grid-cols-2 lg:grid-cols-3 lg:gap-28 p-4">
                    {tutorsData.map((t)=>{
                        return(
                        <div key={t.tid}>
                            <div className="cursor-pointer relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                                <a href={'/tutors/tutor?tid=' + t.tid.toString()}>
                                    <LazyLoadImage
                                    alt={""}
                                    className="object-scale-down w-full h-52 xl:h-92"
                                    src={process.env.PUBLIC_URL +'/'+ t.img}
                                    />
                                    <div className="flex flex-col px-5 py-2 transition-opacity duration-300 bg-opacity-75">
                                    <p className="mt-4 mb- text-lg lg:text-2xl font-bold text-main">
                                        {t.name}
                                    </p>
                                    <p className="mb-3 text-xs lg:text-sm text-black">
                                        {t.title.map((ti)=>{
                                            return(
                                                <span className="p-1 mr-1 lg:p-2 rounded-lg bg-grey lg:mr-2 inline-block mb-1">
                                                    {ti}
                                                </span>
                                            )
                                        })}
                                    </p>
                                    </div>
                                </a>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
            </section>
            <Footer></Footer>   
        </>
    )
}

export default Tutors;