import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import SubHeaderBlack from '../components/SubHeaderBlack';
import { CoursesData } from '../data/CoursesData';
function ArtCourses(){

    return (
        <>
            <Header></Header>
            <SubHeaderBlack title={"藝術課程"}></SubHeaderBlack>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-main sm:text-4xl md:mx-auto">
                            藝術課程查詢
                        </h2>
                    </div>
                    <div className="grid gap-10 grid-cols-2 lg:grid-cols-4 lg:gap-14 p-4">
                        {CoursesData["art"].map((c)=>{
                            return(
                            <div key={c.title}>
                                <div className="cursor-pointer relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                                    <a href={"/courses/art/course?cid=" + c.cid}>
                                        <img
                                        className="object-cover w-full h-32 xl:h-48"
                                        style={{"object-position":"95% 0"}}
                                        src={process.env.PUBLIC_URL +'/'+ c.img}
                                        />
                                        <div className="flex flex-col px-5 py-2 transition-opacity duration-300 bg-opacity-75">
                                        <p className="mt-2 mb-2 text-lg lg:text-xl font-bold text-main">
                                            {c.title}
                                        </p>
                                        </div>
                                    </a>
                                    
                                </div>
                                <div>
                            </div>
                            </div>)
                        })}
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    )

}

export default ArtCourses;