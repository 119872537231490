import React, { useEffect, useState } from 'react';
import Sketch from "react-p5";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typewriter from 'typewriter-effect';
import '../css/Model2.css';
import ImageSlider from '../components/ImageSlider';
let bubbles;

function Home(props) {

    function reveal(c) {
        var target = document.querySelectorAll(c);
      
        for (var i = 0; i < target.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = target[i].getBoundingClientRect().top;
          var elementVisible = 150;
      
          if (elementTop < windowHeight - elementVisible) {
            target[i].classList.add("active");
          } else {
            target[i].classList.remove("active");
          }
        }
      }
    useEffect(()=>{
        window.addEventListener("scroll", function(){reveal(".reveal")});
        window.addEventListener("scroll", function(){reveal(".fadeIn")});
    },[])

    
    function windowResized(p5) {
        p5.resizeCanvas(document.getElementById("p5-header-home").offsetWidth, document.getElementById("p5-header-home").offsetHeight);
    }

    class Bubble
    {
        constructor(x, y, p5) {        
            this.x = x;
            this.y = y;
            this.dy = p5.random(3, 5);
            this.r = 0.5
            this.dr = p5.random(0.01, 0.3)
            this.dx = p5.random(-1.5, 1.5)
            this.a = p5.random(0, 1)
            this.color = p5.color(p5.random(200, 255), p5.random(50, 180), p5.random(0, 100), 255 * this.a)
        // this.clr = random(this.colors)
        }
        display(p5)
        {
            p5.noStroke()
            // fill(this.clr, 255 * this.a)
            p5.fill(this.color)
            p5.circle(this.x, this.y, this.r)
            this.y += this.dy
            this.x += this.dx
            this.r += this.dr
        }
    }

    function setup(p5, canvasParentRef) 
    {
        p5.createCanvas(document.getElementById("p5-header-home").offsetWidth, document.getElementById("p5-header-home").offsetHeight).parent(canvasParentRef);
        bubbles = []
    }
    
    function draw(p5) 
    {
        p5.background('#3985A5')
        bubbles.forEach(b => b.display(p5))
    //   delete object if bubble out of display
        bubbles = bubbles.filter(b => b.y - b.r < p5.height)

        bubbles.push(new Bubble(p5.mouseX, p5.mouseY, p5))
        
    }
    return(
    <>  
        <Header></Header>
        <div id="p5-header-home" className="w-[100%] relative" style={{"height":"100vh"}}>
            <Sketch setup={setup} draw={draw} windowResized={windowResized}/>
            <div style={{"translate": "-50% -50%"}}className="text-center absolute top-2/4 left-2/4 w-[80vw]">
                <h1 className="font-extrabold waviy headerTitle" style={{"color":"white"}}>
                    <span style={{"--i":"1"}}>C</span>
                    <span style={{"--i":"2"}}>a</span>
                    <span style={{"--i":"3"}}>d</span>
                    <span style={{"--i":"4"}}>e</span>
                    <span style={{"--i":"5"}}>n</span>
                    <span style={{"--i":"6"}}>z</span>
                    <span style={{"--i":"7"}}>a</span>
                    <span style={{"--i":"8"}}>卡</span>
                    <span style={{"--i":"9"}}>式</span>
                    <span style={{"--i":"10"}}>藝</span>
                    <span style={{"--i":"11"}}>術</span>
                </h1>
                <br></br>
                <h4 className="font-light headerSubTitle" style={{"color":"white"}}>
                    LIFE IS A SONG｜LOVE IS THE MUSIC
                </h4>
                <h4 className="font-light headerSubTitle" style={{"color":"white"}}>
                    古典 x 流行｜教育 x 演出
                </h4>
            </div>
        </div>
        <section class="flex flex-col max-w-6xl px-4 pt-24 pb-32 mx-auto bg-white sm:px-6 lg:px-8 ">
            <div class="flex flex-col items-center justify-center max-w-2xl mx-auto text-center">
                <h2 class="text-3xl text-gray-800 md:text-6xl">Small Team. Big Hearts.</h2>
                <p class="pt-6 text-xl font-light text-gray-400 sm:text-2xl">
                    <span className="text-main font-bold">卡式Cadenza</span>主要發展敲擊樂、管樂、弦樂，及藝術課程及相關演出，以融合古典及流行音樂作為核心價值。
                </p>
            </div>
            <div class="w-full mt-20 mb-4 aspect-w-10 aspect-h-4 reveal">
                <div class="grid grid-cols-12 grid-rows-5 gap-2 sm:gap-3 md:gap-4">
                <div id="img1" class="col-span-4 row-span-5 overflow-hidden rounded-md sm:rounded-lg">
                    <ImageSlider
                        cn={"images1"}
                        timeGap={12000}
                        images={[process.env.PUBLIC_URL +'/p7.jpg', process.env.PUBLIC_URL +'/p2.jpg']}
                    ></ImageSlider>
                    {/* <img
                    src="https://cadenzamacau.s3.ap-southeast-2.amazonaws.com/p1.jpg"
                    alt=""
                    class="object-cover object-center w-full h-full"
                    /> */}
                </div>
                <div id="img2" class="col-span-3 row-span-3 overflow-hidden rounded-md sm:rounded-lg">
                    <ImageSlider
                        cn={"images2"}
                        timeGap={14000}
                        images={[process.env.PUBLIC_URL +'/p3.jpg', process.env.PUBLIC_URL +'/p4.jpg']}
                    ></ImageSlider>
                </div>
                <div id="img3" class="col-span-2 row-span-2 overflow-hidden rounded-md sm:rounded-lg">
                    <ImageSlider
                        cn={"images3"}
                        timeGap={24000}
                        images={[process.env.PUBLIC_URL +'/p6.jpg', process.env.PUBLIC_URL +'/p5.jpg']}
                    ></ImageSlider>
                </div>
                <div id="img4" class="col-span-3 row-span-2 overflow-hidden rounded-md sm:rounded-lg">
                    <ImageSlider
                        cn={"images4"}
                        timeGap={20000}
                        images={[process.env.PUBLIC_URL +'/p1.jpg', process.env.PUBLIC_URL +'/p8.jpg']}
                    ></ImageSlider>
                </div>
                <div id="img5" class="col-span-5 row-span-3 overflow-hidden rounded-md sm:rounded-lg">
                    <ImageSlider
                        cn={"images5"}
                        timeGap={28000}
                        images={[process.env.PUBLIC_URL +'/p10.jpg', process.env.PUBLIC_URL +'/p9.jpg']}
                    ></ImageSlider>
                </div>
                <div id="img6" class="col-span-3 row-span-2 overflow-hidden rounded-md sm:rounded-lg">
                    <ImageSlider
                        cn={"images6"}
                        timeGap={22000}
                        images={[process.env.PUBLIC_URL +'/p13.jpg', process.env.PUBLIC_URL +'/p11.jpg']}
                    ></ImageSlider>
                </div>
                </div>
            </div>
        </section>
        <section class="flex flex-col w-full sm:flex-row pb-24 reveal" style={{"background":"rgb(250, 250, 250)"}}>
            <div class="relative flex-1 px-6 pt-6 sm:p-0">
                <img
                class="object-cover object-center w-full h-full pb-px rounded-lg sm:rounded-none"
                src={process.env.PUBLIC_URL +'/p12.jpg'}
                alt=""
                />
                <div
                style={{"background":"rgb(250, 250, 250)"}}
                class="absolute inset-y-0 right-0 hidden w-16 h-full transform translate-x-1/2 skew-x-6  sm:block"
                ></div>
            </div>
            <div class="flex-1 " style={{"background":"rgb(250, 250, 250)"}}>
                <div class="flex flex-col justify-center h-full max-w-xl p-6 sm:p-8 sm:pl-16 md:p-20">
                <h2 class="title text-main">
                    教學特色
                </h2>
                <br></br>
                <h4 className="text-2xl">
                    <Typewriter
                        options={{
                            strings: ['豐富演出，教育經驗', '多場比賽經驗', '專業資格認可', '高質素的教育'],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </h4>
                <p class="mt-6 text-lg text-gray-600">
                    本中心擁有各地豐富演出及教育經驗及專業資格認可的導師，能夠為學員提供高質素的音樂和藝術教育。我們會為每位學生定制教學進程，根據學生的程度、興趣和需求，量身訂造教學內容，以達到最佳的學習效果。讓我們一起享受音樂和藝術帶來的美好！
                </p>
                <div class="flex flex-row justify-start gap-2 mt-8">
                    <div className="mr-auto">
                        <h3 class="text-xl font-bold text-main">> 500名</h3>
                        <p class="text-gray-800">學生</p>
                    </div>
                    <div className="mr-auto">
                        <h3 class="text-xl font-bold text-main">> 100場</h3>
                        <p class="text-gray-800">演出</p>
                    </div>
                    <div className="mr-auto">
                        <h3 class="text-xl font-bold text-main">> 50場</h3>
                        <p class="text-gray-800">比賽</p>
                    </div>
                </div>
                </div>
                
            </div>
            <div
                className="w-full absolute bottom-0 transform translate-y-1/2 -skew-y-3 h-[6vw] z-10" style={{"background": "#edf8f9"}}
            ></div>
        </section>
        <section className="relative w-full bg-gray-700 mb-[9vw] pt-[3vw]" style={{"background": "#edf8f9"}}>
        <section class="w-full py-8">
            <div class="flex flex-col max-w-6xl px-4 pt-16 pb-20 mx-auto sm:px-6 lg:px-8">
                <div class="flex flex-col items-center justify-center max-w-2xl mx-auto text-center">
                    <h2 className='headerTitle text-main text-center  mb-[5vw]'>服務包括</h2>
                </div>
                <div class="w-full mt-20 mb-4">
                <div
                    class="grid items-center w-full grid-cols-1 grid-rows-6 gap-24 mx-auto justify-items-center sm:grid-cols-2 sm:grid-rows-3 sm:max-w-lg md:grid-cols-3 md:grid-rows-2 md:max-w-5xl"
                >
                    <div
                    class="cursor-pointer max-w-[250px] px-auto"
                    >
                        <a href="/courses/music" className="flex flex-col items-center col-span-1 row-span-1 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="music" className="w-14 h-14 text-second animate-bounce ">
                                <path fill="#3985A5" d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z">
                                </path>
                            </svg>
                            <h3 class="mt-4 text-gray-800">音樂課程</h3>
                            <p class="mt-2 text-gray-400" style={{"color":"rgb(120, 133, 140)"}}>
                                |弦樂|敲擊樂|管樂|唱歌|結他|
                            </p>
                        </a>
                    </div>

                    <div
                    class="cursor-pointer max-w-[250px] px-auto"
                    >
                        <a href="/courses/art" className="flex flex-col items-center col-span-1 row-span-1 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="brush-alt" className="w-14 h-14 animate-bounce" style={{"animation-delay":"100ms"}}>
                                <path fill="#3985A5" d="M12,18a1,1,0,1,0,1,1A1,1,0,0,0,12,18ZM18,1H6A1,1,0,0,0,5,2v9a3,3,0,0,0,3,3H9v2.37a4,4,0,1,0,6,0V14h1a3,3,0,0,0,3-3V2A1,1,0,0,0,18,1ZM12,21a2,2,0,0,1-1.33-3.48,1,1,0,0,0,.33-.74V14h2v2.78a1,1,0,0,0,.33.74A2,2,0,0,1,12,21Zm5-10a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V10H17Zm0-3H7V3H17Z">
                                </path>
                            </svg>
                            <h3 class="mt-4 text-gray-800">藝術課程</h3>
                            <p class="mt-2 text-gray-400" style={{"color":"rgb(120, 133, 140)"}}>|花藝|香水|美術|手工|</p>
                        </a>
                    </div>

                    <div
                    class="cursor-pointer max-w-[250px] px-auto"
                    >
                        <a href="/courses/special" className="flex flex-col items-center col-span-1 row-span-1 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="pen" className="w-14 h-14 animate-bounce" style={{"animation-delay":"200ms"}}>
                                <path fill="#3985A5" d="M22,7.24a1,1,0,0,0-.29-.71L17.47,2.29A1,1,0,0,0,16.76,2a1,1,0,0,0-.71.29L13.22,5.12h0L2.29,16.05a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1H7.24A1,1,0,0,0,8,21.71L18.87,10.78h0L21.71,8a1.19,1.19,0,0,0,.22-.33,1,1,0,0,0,0-.24.7.7,0,0,0,0-.14ZM6.83,20H4V17.17l9.93-9.93,2.83,2.83ZM18.17,8.66,15.34,5.83l1.42-1.41,2.82,2.82Z">
                                </path>
                            </svg>
                            <h3 class="mt-4 text-gray-800">特別課程</h3>
                            <p class="mt-2 text-gray-400" style={{"color":"rgb(120, 133, 140)"}}>
                                |口才技巧|填詞|流行唱歌|
                            </p>    
                        </a>
                    </div>

                    <div
                    class="cursor-pointer max-w-[250px] px-auto"
                    >
                        <a href="/lendinginstrument" className="flex flex-col items-center col-span-1 row-span-1 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="box" className="w-14 h-14 animate-bounce" style={{"animation-delay":"300ms"}}>
                            <path fill="#3985A5" d="M20.49,7.52a.19.19,0,0,1,0-.08.17.17,0,0,1,0-.07l0-.09-.06-.15,0,0h0l0,0,0,0a.48.48,0,0,0-.09-.11l-.09-.08h0l-.05,0,0,0L16.26,4.45h0l-3.72-2.3A.85.85,0,0,0,12.25,2h-.08a.82.82,0,0,0-.27,0h-.1a1.13,1.13,0,0,0-.33.13L4,6.78l-.09.07-.09.08L3.72,7l-.05.06,0,0-.06.15,0,.09v.06a.69.69,0,0,0,0,.2v8.73a1,1,0,0,0,.47.85l7.5,4.64h0l0,0,.15.06.08,0a.86.86,0,0,0,.52,0l.08,0,.15-.06,0,0h0L20,17.21a1,1,0,0,0,.47-.85V7.63S20.49,7.56,20.49,7.52ZM12,4.17l1.78,1.1L8.19,8.73,6.4,7.63Zm-1,15L5.5,15.81V9.42l5.5,3.4Zm1-8.11L10.09,9.91l5.59-3.47L17.6,7.63Zm6.5,4.72L13,19.2V12.82l5.5-3.4Z">
                                </path>
                            </svg>
                            <h3 class="mt-4 text-gray-800">樂器租借</h3>
                            <p class="mt-2 text-gray-400" style={{"color":"rgb(120, 133, 140)"}}>
                                |爵士鼓|電子琴|木箱鼓|
                            </p>
                        </a>
                    </div>

                    <div
                    class="cursor-pointer max-w-[250px] px-auto"
                    >
                        <a href="/lendingplace" className="flex flex-col items-center col-span-1 row-span-1 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="building" className="w-14 h-14 animate-bounce" style={{"animation-delay":"400ms"}}>
                                <path fill="#3985A5" d="M14,8h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2Zm0,4h1a1,1,0,0,0,0-2H14a1,1,0,0,0,0,2ZM9,8h1a1,1,0,0,0,0-2H9A1,1,0,0,0,9,8Zm0,4h1a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,8H20V3a1,1,0,0,0-1-1H5A1,1,0,0,0,4,3V20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-8,0H11V16h2Zm5,0H15V15a1,1,0,0,0-1-1H10a1,1,0,0,0-1,1v5H6V4H18Z">
                                </path>
                            </svg>
                            <h3 class="mt-4 text-gray-800">場地租借</h3>
                            <p class="mt-2 text-grey" style={{"color":"rgb(120, 133, 140)"}} >
                                |Band房|鋼琴房|敲擊樂房|
                            </p>    
                        </a>
                    </div>

                    <div
                    class="cursor-pointer flex flex-col max-w-[250px] col-span-1 items-center row-span-1 text-center px-auto"
                    >
                        <a href="/blogs/blog?id=clkgt02mvm3el0b12gpvu4rkx" className="flex flex-col items-center col-span-1 row-span-1 text-center">                       
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="megaphone" className="w-14 h-14 animate-bounce" style={{"animation-delay":"500ms"}}>
                                <path fill="#3985A5" d="M19.99121,2.002a.99943.99943,0,0,0-1,1v.63672a9.03617,9.03617,0,0,1-7,3.36328h-6a3.00328,3.00328,0,0,0-3,3v2a3.00328,3.00328,0,0,0,3,3H6.475L4.07227,20.6084A.99931.99931,0,0,0,4.99121,22.002h4a.99857.99857,0,0,0,.91895-.60644L12.63391,15.04a9.02805,9.02805,0,0,1,6.3573,3.32507V19.002a1,1,0,0,0,2,0v-16A.99942.99942,0,0,0,19.99121,2.002Zm-14,11a1.00067,1.00067,0,0,1-1-1v-2a1.00068,1.00068,0,0,1,1-1h1v4Zm2.34082,7H6.50781l2.14258-5h1.82422ZM18.99121,15.5238a11.052,11.052,0,0,0-7-2.52185h-3v-4h3a11.05281,11.05281,0,0,0,7-2.52234Z">
                                </path>
                            </svg>
                            <h3 class="mt-4 text-gray-800">演出</h3>
                            <p class="mt-2 text-gray-400" style={{"color":"rgb(120, 133, 140)"}}>
                                各種樂器演出
                            </p>
                        </a> 
                    </div>
                </div>
                </div>
            </div>
            </section>
            <div
                className="w-full absolute bottom-0 transform translate-y-1/2 -skew-y-3 h-[6vw] z-5 shadow-lg" style={{"background": "#edf8f9"}}
            ></div>
        </section>
        
        <section className="relative w-full pt-[5vw]">
            <div id='model2'>
                <h2 className='headerTitle text-main text-center  mb-[5vw]'>專業導師</h2>
                <div className="members fadeIn">
                <div className="member fadeIn">
                    <a href="/tutors/tutor?tid=1">
                    <img style={{"width": "400px","height": "400px","object-fit": "cover", "object-position":"95% 0"}} src={process.env.PUBLIC_URL +'/Vicky.jpg'}/>
                    <div className="description">
                        <h3 className="title">Vicky</h3>
                        <h2>敲擊樂、流行爵士鼓</h2>
                        <div className="social-media">
                        </div>
                    </div>
                    </a>
                </div>
                <div className="member fadeIn">
                    <a href="/tutors/tutor?tid=4">
                    <div className="description">
                        <h3 className="title">EL</h3>
                        <h2>流行爵士鼓</h2>
                        <div className="social-media">
                        </div>
                    </div>
                    <img style={{"width": "400px","height": "400px","object-fit": "cover", "object-position":"100% 0"}} src={process.env.PUBLIC_URL +'/EL.jpg'}/>
                    </a>
                </div>
                <div className="member fadeIn">
                    <a href="/tutors/tutor?tid=10">
                    <img style={{"width": "400px","height": "400px","object-fit": "cover", "object-position":"95% 0"}} src={process.env.PUBLIC_URL +'/Gabriel.jpg'}/>
                    <div className="description">
                        <h3 className="title">Gabriel</h3>
                        <h2>鋼琴、古箏</h2>
                        <div className="social-media">
                        </div>
                    </div>
                    </a>
                </div>
                </div>
            </div>
            <div class="grid h-[30vh] w-full place-items-center">
                <a href="/tutors">
                <button class="group relative h-12 w-48 overflow-hidden rounded-lg bg-white text-lg shadow">
                    <div class="absolute inset-0 w-0 bg-main transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                    <span class="relative text-black group-hover:text-white">查看更多</span>
                </button>
                </a>
            </div>
        </section>
       <Footer></Footer>
    </>);

}



export default Home;
