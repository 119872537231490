import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderBlack from "../components/SubHeaderBlack"
import Footer from "../components/Footer";
function VideoShare(){
    return (
        <>
            <Header></Header>
            <SubHeaderBlack title={"影片分享"}></SubHeaderBlack>
            <section >
                <div class="max-w-6xl px-4 pb-8 mx-auto transform sm:px-6 lg:px-8 lg:mt-24 lg:pb-16 ">
                    <div class="grid grid-cols-1 gap-8 mt-12 mb-8 sm:grid-cols-2 lg:grid-cols-3 lg:space-y-0" >
                        <div class="mx-auto">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FCadenza.edu.mo%2Fvideos%2F1411089536235030%2F&show_text=true&width=267&t=0" width="267" height="591"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                        <div class="mx-auto">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCadenza.edu.mo%2Fvideos%2F1007206904529409%2F&show_text=true&width=560&t=0" width="300" height="429"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                        <div class="mx-auto">             
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCadenza.edu.mo%2Fvideos%2F984541986398065%2F&show_text=true&width=560&t=0" width="300" height="429"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                        <div class="mx-auto">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FCadenza.edu.mo%2Fvideos%2F1001357460853145%2F&show_text=true&width=267&t=0" width="267" height="591"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                        <div class="mx-auto">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCadenza.edu.mo%2Fvideos%2F721122816840159%2F&show_text=true&width=560&t=0" width="300" height="300"  allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                        <div class="mx-auto">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCadenza.edu.mo%2Fvideos%2F1294380387903401%2F&show_text=true&width=560&t=0" width="300" height="300"  allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                        <div class="mx-auto">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FCadenza.edu.mo%2Fvideos%2F1171790750275062%2F&show_text=true&width=560&t=0" width="300" height="300"    allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </div>
                    </div>
                </div>
            
            </section>

            <Footer></Footer>
        </>
    )

}

export default VideoShare;