import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderBlack from "../components/SubHeaderBlack"
import Footer from "../components/Footer";
import { useSearchParams } from 'react-router-dom';
import {CoursesData} from "../data/CoursesData";
function MusicCourse(){
    const [searchParams, setSearchParams] = useSearchParams({cid:'-1'});
    const cid = searchParams.get('cid');
    const c = CoursesData["music"][cid];
    return(
        <>
            <Header></Header>
            <SubHeaderBlack title={"音樂課程"}></SubHeaderBlack>
            {c?<section className="px-4 py-16 mx-auto lg:max-w-5xl md:px-8 lg:px-8 lg:py-20">
                    <h1 className='text-5xl lg:text-6xl text-main  mb-4 mt-4'>{c.titleFull}</h1>
                    <div className="w-[100%]">
                        <img style={{"object-fit": "cover", "object-position":"95% 0"}} className="rounded w-fit lg:h-64 h-48"src={process.env.PUBLIC_URL +'/'+ c.img}></img>
                    </div>
                    <div>
                        <p className="my-4 text-xl text-main">
                            {c.p1}
                        </p>
                        <p className="mb-3 text-xs lg:text-sm text-gray-500">
                            {c.p2.map((p)=>{
                                return(
                                    <p className="py-2 inline-block mb-3 leading-7">
                                        {p}
                                    </p>
                                )
                            })}
                        </p>
                        <hr></hr>
                    </div>
                <hr></hr>
                <div className="my-4">
                    <h2 className='text-4xl text-main  mb-4 mt-4'>課程資訊：</h2>
                    <p className='mb-2'>
                        適合學習年齡：{c.age}歲或以上
                    </p>
                    <p className='mb-2'>
                        上課模式：{c.mode}
                    </p>
                    <p className='mb-2'>
                        {c.exam}
                    </p>
                    <p className='mb-2'>
                        {c.continuingEducation?"*15歲以上可使用持續進修基金":""}
                    </p>
                </div>
            </section>:""}
            <Footer></Footer>   
        </>
    )
}

export default MusicCourse;