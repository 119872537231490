import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderWhite from "../components/SubHeaderWhite";
import Footer from "../components/Footer";
import { CoursesData } from '../data/CoursesData';
function MusicCourses(){

    return (
        <>
            <Header></Header>
            <SubHeaderWhite title={"音樂課程"}></SubHeaderWhite>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-main sm:text-4xl md:mx-auto">
                            音樂課程查詢
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg">
                            *號為可用持續進修
                        </p>
                    </div>
                    <div className="grid gap-10 grid-cols-2 lg:grid-cols-4 lg:gap-14 p-4">
                        {CoursesData["music"].map((c)=>{
                            return(
                            <div key={c.title}>
                                <div className="cursor-pointer relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                                    <a href={"/courses/music/course?cid=" + c.cid}>
                                        <img
                                        className="object-cover w-full h-32 xl:h-32"
                                        style={{"object-position":"95% 0"}}
                                        src={process.env.PUBLIC_URL +'/'+ c.img}
                                        />
                                        <div className="flex flex-col px-5 py-2 transition-opacity duration-300 bg-opacity-75">
                                        <p className="mt-2 mb-2 text-lg lg:text-xl font-bold text-main">
                                            {c.title}{c.continuingEducation?"*":""}
                                        </p>
                                        </div>
                                        <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                                            <p className="mb-4 text-lg font-bold text-gray-100">
                                                {c.continuingEducation?"可用持續進修":""}
                                            </p>
                                            <p className="mb-1 text-xs tracking-wide text-gray-100">
                                                {c.mode}
                                            </p>
                                            <p className="mb-1 text-xs tracking-wide text-gray-100">
                                                {c.age}歲以上
                                            </p>
                                        </div>
                                    </a>
                                    
                                </div>
                                <div>
                            </div>
                            </div>)
                        })}
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    )

}

export default MusicCourses;