import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
    MusicalNoteIcon,
    SparklesIcon,
    PuzzlePieceIcon,
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

const products = [
  { name: '音樂課程', description: '盡情享受音樂帶來的愉悅感受', href: '/courses/music', icon: MusicalNoteIcon },
  { name: '藝術課程', description: '藝術可以讓我們更好地理解世界和自己', href: '/courses/art', icon: SparklesIcon },
  { name: '特別課程', description: '探索新的技能和知識領域', href: '/courses/special', icon: PuzzlePieceIcon },
]
const lendingServices = [
  { name: '樂器租借',  href: '/lendinginstrument'},
  { name: '場地租借',  href: '/lendingplace'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-main fixed top-0 left-0 z-10 w-[100%]">
      <nav className="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-10 w-auto rounded" src={process.env.PUBLIC_URL + "/logo2.png"} alt="" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <Popover.Group className="hidden lg:flex lg:gap-x-12 ">

            <a href="/tutors" className="text-sm font-semibold leading-6 text-white">
                導師資訊
            </a>
            <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
                    課程資訊
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </Popover.Button>

                <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                >
                <Popover.Panel style={{"background-color":"white"}} className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                    {products.map((item) => (
                        <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-10"
                        >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                        </div>
                        <div className="flex-auto">
                            <a href={item.href} className="block font-semibold text-gray-900">
                            {item.name}
                            <span className="absolute inset-0" />
                            </a>
                            <p className="mt-1 text-gray-600">{item.description}</p>
                        </div>
                        </div>
                    ))}
                    </div>
                    <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-10">
                    </div>
                </Popover.Panel>
                </Transition>
            </Popover>
            <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
                    租借服務
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                </Popover.Button>

                <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                >
                <Popover.Panel  className="bg-white absolute -left-8 top-full z-10 mt-3 w-32 max-w-md overflow-hidden rounded-3xl shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                    {lendingServices.map((item) => (
                        <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-100"
                        >
                        <div className="flex-auto">
                            <a href={item.href} className="block font-semibold text-gray-900">
                            {item.name}
                            <span className="absolute inset-0" />
                            </a>
                        </div>
                        </div>
                    ))}
                    </div>
                    <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                    </div>
                </Popover.Panel>
                </Transition>
            </Popover>
            <a href="/video" className="text-sm font-semibold leading-6 text-white">
                影片分享
            </a>
            <a href="/blogs" className="text-sm font-semibold leading-6 text-white">
                活動及資訊
            </a>
            <a href="/enroll" className="text-sm font-semibold leading-6 text-white">
                立即報名
            </a>
            <a href="/contact" className="text-sm font-semibold leading-6 text-white">
                聯絡我們
            </a>
        </Popover.Group>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="bg-main fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src={process.env.PUBLIC_URL + "/logo2.png"}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="/tutors"
                  className="text-white -mx-3 block rounded-lg px-3 py-2 text-lg font-light leading-7 hover:bg-cyan-800"
                >
                    導師資訊
                </a>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-lg font-light leading-7 text-white hover:bg-cyan-800">
                      課程資訊
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-lg font-light leading-7 text-white hover:bg-cyan-800"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-lg font-light leading-7 text-white hover:bg-cyan-800">
                        租借服務 
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {lendingServices.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-lg font-light leading-7 text-white hover:bg-cyan-800"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="/video"
                  className="-mx-3 block rounded-lg px-3 py-2 text-lg font-light leading-7 text-white hover:bg-cyan-800"
                >
                    影片分享
                </a>
                <a
                  href="/blogs"
                  className="-mx-3 block rounded-lg px-3 py-2 text-lg font-light leading-7 text-white hover:bg-cyan-800"
                >
                    活動及資訊
                </a>
                <a
                  href="/enroll"
                  className="-mx-3 block rounded-lg px-3 py-2 text-lg font-light leading-7 text-white hover:bg-cyan-800"
                >
                    立即報名
                </a>
                <a
                  href="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-lg font-light leading-7 text-white hover:bg-cyan-800"
                >
                    聯絡我們
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}



export default Header;
