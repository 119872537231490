import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderWhite from "../components/SubHeaderWhite";
import Footer from "../components/Footer";
import { useSearchParams } from 'react-router-dom';
import {CoursesData} from "../data/CoursesData";

function SpecialCourse(){
    const [searchParams, setSearchParams] = useSearchParams({cid:'-1'});
    const cid = searchParams.get('cid');
    const c = CoursesData["special"][cid];
    return (
        <>
            <Header></Header>
            <SubHeaderWhite title={"特別課程"}></SubHeaderWhite>
            {c?<section className="px-4 py-16 mx-auto lg:max-w-5xl md:px-8 lg:px-8 lg:py-20">
                    <h1 className='text-5xl lg:text-6xl text-main  mb-4 mt-4'>{c.title}</h1>
                    <div className="w-[100%]">
                        <img style={{"object-fit": "cover", "object-position":"95% 0"}} className="rounded w-fit lg:h-64 h-48"src={process.env.PUBLIC_URL +'/'+ c.img}></img>
                    </div>
                    <div>
                        <pre className="my-4 text-base text-gray-600 whitespace-pre-wrap leading-7">
                            {c.p}
                        </pre>
                        <hr></hr>
                    </div>
                    <div className="my-4">
                        <h2 className='text-4xl text-main  mb-4 mt-4'>課程資訊：</h2>
                        <pre className="my-4 text-base text-gray-600 whitespace-pre-wrap leading-7">
                            {c.p2}
                        </pre>
                    </div>
            </section>:""}
            <Footer></Footer>
        </>
    )

}

export default SpecialCourse;