export const TutorsData = [
        {
            tid:1,
            img: "Vicky.jpg",
            nameFull:"唯琪妹/Vicky姐姐",
            title2:"船長🥁",
            name: "Vicky" ,
            title:["敲擊樂","流行爵士鼓"],
            icon:"💀",
            p1:"玩味音樂·最緊要開心",
            p2:"遊走於古典與流行音樂海洋的唯琪妹，利用敲擊樂的所有樂器，演奏出讓人沉醉的狂歡音樂，以豐富的『航海』經驗，帶領學員一同進入音樂大海世界，尋找學習音樂那份最珍貴的寶藏🏴‍☠️",
            p3:"唯琪妹讀書時期開始參與管樂隊演出，在音樂大海中經歷過敲擊樂隊及爵士樂隊，進入新世界後再迎戰流行音樂👊🏻航行期間曾到訪各地交流、演出及比賽，現全力發展音樂教育及演出的大冒險事業。"
        },
        {
            tid:2,
            img: "Joey.jpg",
            nameFull:"Joey姐姐",
            title2:"內部系戰士🥁",
            name: "Joey" ,
            title:["流行爵士鼓"],
            icon:"🌙",
            p1:"療癒· 溫柔姐姐👩🏻‍🦰",
            p2:"以鼓棒發動「月光療癒進化」，用愛心教導小朋友敲擊出不同的節奏與速度，再發動「月冕星塵」，令他們在療癒開心的環境下進化成勇敢的戰士。",
            p3:"Joey姐姐畢業於華南師範大學音樂教育專業，擅長小朋友爵士鼓教學，與小朋友建立互相信任關係，專注於小朋友成長發展教學。"
        },
        {
            tid:3,
            img: "EL.jpg",
            nameFull:"El哥哥",
            title2:"一擊🥁",
            name: "Joel" ,
            title:["流行爵士鼓"],
            icon:"👊🏽",
            p1:"骨格精奇 • 武功蓋世",
            p2:"曾經被音樂拯救過，燃起了小時候想成為鼓手的夢想🔥。\n接下來持續每天不間斷訓練blast beat(強勁的爵士鼓技巧)，努力提升音樂的戰鬥力與身體機能，最終打破對自我對音樂的限制！\n透過不斷訓練及實戰，變得更強大，最終練成了大肚腩，但也因此獲得最強的力量，一般使用連續普通打擊，必要時將使出認真的一擊⋯",
            p3:"澳門樂隊scamper的鼓手。"
        },
        {
            tid:4,
            img: "Hong.jpg",
            nameFull:"Hong Sir",
            title2:"坦克騎士🥁",
            name: "Hong" ,
            title:["流行爵士鼓"],
            icon:"💪🏻",
            p1:"血牛 · 坦克🛡️",
            p2:"擁有厚實的身軀和強勁的節拍感，永遠站在學生的最前線，用心為學生著想，利用自己於其他地區學習爵士鼓的經驗為學生抵擋外來的攻擊，強大的身軀有一顆善良的心，是學生及家長最信賴的老師。",
            p3:"在台灣讀書時開始接觸音樂，熱愛台灣地下樂團音樂文化，剛接觸的是結他，接觸爵士鼓後喜歡上打鼓，就開始報復式打鼓！戰鬥力及力量都很強，必要時，也會充當卡式隊伍的強大支柱。"
        },
        {
            tid:5,
            img: "Crixus.jpg",
            nameFull:"Crixus哥哥",
            title2:"刺客🥁",
            name: "Crixus" ,
            title:["流行爵士鼓"],
            icon:"🦅",
            p1:"就是一個識打鼓的人",
            p2:"憑住喜愛搞怪的性格，無論小朋友或大人，都能融入一個輕鬆愉快的氣氛當中。宗旨「凡事皆允」：不怕你錯一萬次，最怕一次也不試。無論錯了幾多次，都會非常有耐性地繼續聆聽及糾正，絕對是EQ滿分的爵士鼓導師。",
            p3:"擁有7年爵士鼓教學經驗，最愛聽Hard Rock與遊戲OST。致力與學生相輔相成，一同為學習成才而戰。喜愛正能量，是卡式隊伍的信仰戰士。"
        },
        {
            tid:6,
            img: "Miu.jpg",
            nameFull:"Miu哥哥",
            title2:"雄英🥁",
            name: "Miu" ,
            title:["流行爵士鼓"],
            icon:"🔥",
            p1:"天賦異稟，竭盡所能",
            p2:"從小沒有「個性」的特殊體質，沒有特別喜好，在機緣巧合下，繼承了武功蓋世的爵士鼓技術，憑有著絕佳的瞬間判斷力，於短時間內已達到英雄的資格，對自己的要求也很高，凡事竭盡所能。",
            p3:"短時間內考獲Rockschool爵士鼓8級證書，擁有不凡的聽力和寫作能力，能因應學生喜好提供不同的輔助，務求令學生寓娛樂於學習，更立志要成為精通多種樂器的音樂人。"
        },
        {
            tid:7,
            img: "Winky.jpg",
            nameFull:"Winky姐姐",
            title2:"輸出ADC🎹",
            name: "Winky",
            title:["鋼琴","長號","樂理"],
            icon:"🔅",
            p1:"細心、耐性·互動教學",
            p2:"前期利用脈衝炮模式遠距離消耗，強化音樂基礎，中期利用高速位移優勢應對多種難度的曲目與消耗，從而增加自信心並產生對音樂的熱情。\n在團戰中以大範圍音符傷害削減敵方生命力，以音域、樂理、技巧三方面噴射推進，兼顧機動性和爆發，釋放出完美的音樂軌跡。",
            p3:"Winky導師畢業於台灣國立清華大學，音樂演奏專業，2019年成功舉辦個人長號獨奏音樂會，中學畢業於澳門演藝學院音樂學校，參與不少樂團演出，有多年的獨奏和合奏經驗，現專注於教學方面發展。"
        },
        {
            tid:8,
            img: "Gabriel.jpg",
            nameFull:"迦Sir",
            title2:"手指術師🎹",
            name: "Gabriel",
            title:["鋼琴","古箏"],
            icon:"🔅",
            p1:"輔佐、溫柔奶爸👨🏻‍🍼",
            p2:"團體戰時，會利用咒力於琴鍵彈奏和弦，提高樂隊士氣，以不同樂譜應對擁有各種能力的對手；單人模式時，會彈出抒情的流行曲，從精神層面徹底迷惑對手，令其喪失戰意🌬再溫柔的收割靈魂。\n利用反轉術式於鋼琴彈出的流行曲，以《和聲學》及《曲式學》展開迦式·無量音域，療癒自己及隊友，是十分強勁的輸出系溫柔奶爸…",
            p3:"迦Sir畢業於國立臺北藝術大學，音樂理論專業，有7年音樂及藝術的戰鬥教學經驗，擅長單體操控技能（鋼琴及古箏）。"
        },
        {
            tid:9,
            img: "heart.jpg",
            nameFull:"同心老師",
            title2:"樂之柱🎷",
            name: "同心",
            title:["薩克斯管"],
            icon:"🫧",
            p1:"喜歡音樂、自娛自樂、活潑開朗，有著像火般的熱情️‍❤️‍🔥",
            p2:"「我會善盡我的職責，在這裡的每一個人都開心學音樂！」不強人所難，在愉快的環境下，一起分享、探討所喜歡的音樂。",
            p3:"台中教育大學音樂系學士畢業，讀書時期參與管樂團演出🎷"
        },
        {
            tid:10,
            img: "Wai_Gor.jpg",
            nameFull:"威威司令",
            title2:"後勤德魯伊🎹",
            name: "Wai Gor",
            title:["Ukulele","木箱鼓","結他"],
            icon:"🔅",
            p1:"回血治癒後勤加持",
            p2:"團體戰時，會利用木箱鼓擊報，提高樂隊屬性；單人模式時，使用Ukulele及木結他自娛自樂。\n全屬性與多樣性的樂器投能，是團隊的治療及補給後盾。",
            p3:"威威司令持有輔導碩士及心理學學士,擅長特殊教育及青少年兒童發展, 有超過10年音樂戰鬥及團戰經驗, 擅長範圍技(Ukulele結他及木箱鼓團體班)以及木結他班。"
        },
        {
            tid:11,
            img: "z.jpg",
            nameFull:"子康sir",
            title2:"速速攻術師🎸",
            name: "Timothy",
            title:["結他"],
            icon:"💨",
            p1:"Macau Oppa🧔🏻",
            p2:"『天下武功唯快不破』\n擅長使用加速Buff，以簡易明瞭的方式教學結他，提高學生學習成效，減少對學習的誤解及卡關，將流行技巧及韓式音樂，融合成簡易領域教學讓學生感受學音樂最快、最大的成就感。",
            p3:"擁有多於八年結他實戰教學經驗，專長操控大自然木系武器-木結他🪵，仲可以會教授學生K-pop音樂結他彈奏技巧。"
        },
        {
            tid:12,
            img: "g.jpg",
            nameFull:"葉綠素",
            title2:"召喚法師🍃",
            name: "葉綠素",
            title:["藝術課程","平面設計"],
            icon:"🌱",
            p1:"能把看到的東西變成可愛插畫🎨",
            p2:"用一支筆和一張紙，使能召喚出可愛的插畫精靈，經過雙手的東西都可以幻化成藝術品。擅長聽取別人的需求，提供最適合對方的藝術設計方案🖌️",
            p3:"Sally葉倩雯，筆名葉綠素，澳門理工藝術學院平面設計系畢業，日常主要工作內容包括品牌設計、吉祥物創作、插畫及創意手作坊等。\n同時亦經營着自己的IP品牌「葉綠素空間」🌿以葉綠素為藍本的漫畫創作，透過營養素角色的可愛日常趣事，希望帶出積極樂觀的健康生活態度💚"
        },
        {
            tid:13,
            img: "Liona.jpg",
            nameFull:"Liona",
            title2:"靈魂昇華術師🪔",
            name: "Liona",
            title:["香水調配","功能精油香水調配","芳療"],
            icon:"🧪",
            p1:"『香水調配』\n學習用天然精油香精，配合出不同香氣，訂製出屬於自己獨特的香水。\n『功能精油香水調配』\n認識各種天然精油的香氣，學習相對的功效，製作出具療效且芬芳的香氣。\n『芳療』\n天然精油具有生理及心理等療效，適當運用可為身心帶來意想不到的幫助。",
            p2:"用精油香精來配搭出具獨特的療效香水，透過特調的香水，提高學員身、心、靈的健康及幸福指數。\n擅長調香及星座芳療，依據學員的喜好，與學員的溝通下，制訂出適合不同人群及具有意義的個人香氣產品，自用及送禮都十分合適。",
            p3:"Liona是 @whiff_macau 的創辦人，於香港香水學校完成修讀香水鑒賞及調香證書課程，並完成修讀美國NAHA (National Association for Holistic Aromatherapy)芳療師證書課程。Liona一直都鍾情於香味，對於香水製作更感興趣，喜歡嘗試各種品牌的香水及精油相關製品。"
        },
        {
            tid:14,
            img: "c.jpg",
            nameFull: "程文政Kevin",
            title2: "口才精靈🧞‍♂️程文政",
            name: "程文政",
            title:["口才","填詞","歌唱技巧"],
            icon:null,
            p1:"愛與正義的化身，以「playful」為生活宗旨，帶你進入奇妙有趣的言語世界💬",
            p2:"幽默調皮的言語精靈，透過有趣的遊戲進行訓練，盡能力實現每位大人及小朋友的願望，令大家勇於面對人與人之間的相處，自信地站在不同的人生舞台🌟",
            p3:"澳門著名唱作人、司儀、演員及口才培訓及填詞導師、編劇、導演及澳門演藝人協會秘書長\n曾在多間中小學任教，擁有多年教學經驗，主要教授司儀、口才、填詞、唱歌、音樂劇及表演等"
        },
        {
            tid:15,
            img: "chan.jpeg",
            nameFull: "陳慧敏Vivian",
            title2: null,
            name: "陳慧敏",
            title:["唱歌"],
            icon:null,
            p1:"澳門唱作歌手",
            p2:null,
            p3:"曾參與澳廣視節目《Sing擂台》獲得總亞軍，次年參與香港TVB電視節目《超級巨聲2》，因演唱歌曲《歲月的童話》而為人熟悉，更奪得「我最喜愛巨聲金曲」獎項 ，自此活躍於港澳樂壇。\n2012年憑《始終》一曲更奪得第十屆澳廣視至愛新聽力頒獎禮的「至愛歌曲金獎」。\n在2013年獲得IFPI香港唱片銷量大獎的「最暢銷本地女新人」，第三十六屆十大中文金曲頒獎音樂會獲得「最有前途新人獎」；同年創作歌曲《航道》於第一屆「MACA流行歌曲創作大賽」奪得金奬和最佳作曲獎，至愛新聽力得獎歌曲。\n2014年奪得「中國好聲音」澳門站總冠軍。\n曾發佈三張個人專輯，在澳門和香港也曾開個人售票演唱會，亦有多次參演音樂劇、電影中擔當主要角色，同時有九年全職歌手經驗，演唱實力備受肯定。"
        },
        {
            tid:16,
            img: "ivan.jpeg",
            nameFull: "Ivan",
            title2: "進擊之鼓手☄️Ivan",
            name: "Ivan",
            title:["流行爵士鼓"],
            icon:"✊🏻",
            p1:"擁有強大實力💪🏻安全感爆燈！",
            p2:"年少冒險時曾經迷失過，加入訓練軍團後被打鼓救了回來，更發現自己擁有變成巨人的能力，並協助擊退其它巨人，能夠把經常迷失的學生重回正軌！\n跟隨Ivan老師大大力去燃燒你的能量吧🔥",
            p3:"澳門樂隊「青原FIDA」的鼓手\n曾擔任知名組合「Soler」的鼓手，以及香港知名地下樂隊「荔枝王」的鼓手，走遍世界每個角落各大小舞台，演出經驗豐富。"
        },
        {
            tid:17,
            img: "funny.jpeg",
            nameFull: "Funny哥哥",
            title2: "鼓界蠻王⚒️Funny哥哥",
            name: "Funny",
            title:["流行爵士鼓"],
            icon:"💢",
            p1:"瞬間爆發很高，執鼓棍於手而集怒於心，能轉化為打鼓的力量，展示驚人的鼓技。",
            p2:"自細最喜愛聽搖滾及重型音樂，用橡筋當結他尼彈，用曲奇餅罐當鼓打，高中時期踏上夾band之路。希望以自己玩音樂的理念及經歷，與學生一起隨心而動，隨棍而行地打鼓，一同成為🇲🇴音樂界的一份子。",
            p3:"澳門樂隊B記鼓手。\n找到了自己最重要的興趣，由一個花太多時間打機上的人，變成一個陶醉及享受於音樂世界的人；現積極參與澳門或以外地區的音樂活動及工作，包括Band隊比賽、音樂節、街頭演出及婚宴現場演奏等。"
        },
        {
            tid:18,
            img: "hoi.jpeg",
            nameFull: "Hoi開開",
            title2: "臥龍🥁Hoi開開",
            name: "Hoi",
            title:["流行爵士鼓"],
            icon:"🪭",
            p1:"足智多謀，神機妙算",
            p2:"長於巧思，總能為學生找到最具針對性的練鼓計謀和神器，就如同諸葛連弩，木牛流馬。打從出山的一日起，便確立其志向：為學生排解音樂道路上的一切難題，就如同諸葛亮對「興復漢室，北定中原」的目標一樣堅定。",
            p3:"習鼓約十載，獲得Rockschool爵士鼓八級證書，曾參與澳大書院樂隊，並有多次現場演出經驗。"
        },
        {
            tid:19,
            img: "matthew.jpeg",
            nameFull: "Matthew哥哥",
            title2: "超能100🪇Matt Sir/Matthew哥哥",
            name: "Matthew",
            title:["流行爵士鼓","敲擊樂"],
            icon:"🦖",
            p1:"對音樂的熱愛🖖🏻開發創造力",
            p2:"因其微弱的存在感而遭人戲稱為「路人」，但不起眼的他其實天生是一名力量強大的超能力者，為了不讓超能力失控，路人一直在無意識的情形下壓抑著自己的情感，但在力量和感情的衝突中，他學會演奏不同音樂風格，特別在R&B、Neo Soul、Funk、Hip Hop及Rock等領域最為出色，並開始思量如何善用自己的力量與學生產生共鳴。",
            p3:"8歲於演藝學院學習敲擊樂，14歲開始學習爵士鼓，短期內以優異成績考獲爵士鼓8級證書。其後遠赴英國入讀英國BIMM大學倫敦音樂學院，修讀流行音樂表演專業，獲著名鼓手Erik Stams和Julien Brown專業指導。\n畢業回澳後開啟教學生涯，亦以鼓手身份在不同場合演出。"
        },
        {
            tid:20,
            img: "car.jpeg",
            nameFull: "Car姐姐",
            title2: "不孤獨💖Car姐姐",
            name: "Car姐姐",
            title:["敲擊樂"],
            icon:"🦥",
            p1:"帶大家將身體融入呢個節奏😎",
            p2:"「陰鬱的話就去玩音樂！」一直抱持著與別人一起玩音樂的夢想，更認為沉靜的人也可以在樂團發光發熱，從此每天苦練鼓技，能將發出聲響嘅萬物演變成節奏！與學生一起靠音樂讓他人認同自己！",
            p3:"小學開始接觸非洲鼓，沉靜的心卻喜歡熱鬧的氣氛，於中學參與管樂團後開始學習敲擊樂，除管樂團外，亦有參與敲擊樂團的重奏演出，曾隨多隊樂團參與不同類型的演出，演出經驗豐富。"
        },
        {
            tid:21,
            img: "eric.jpeg",
            nameFull: "Eric哥哥",
            title2: "家庭教師👨🏻‍🏫Eric哥哥",
            name: "Eric哥哥",
            title:["小提琴"],
            icon:"👑",
            p1:"享受音樂，開心愉快😊",
            p2:"引導學生們探索音樂的奧秘，開啟他們對音樂的熱情並發掘他們的才華。當到重要時刻時，會進入「超死氣模式」，燃燒心中的火焰，解除身體極限🔥\n對音樂擁有火一般的熱情❤️‍🔥希望將音樂的快樂和感動帶給每一個人🎵",
            p3:"從小開始演奏小提琴，小學時期已加入澳門青年交響樂團，曾參與多隊樂團的演出，擁有多年現場表演的經驗。"
        },
        {
            tid:22,
            img: "sonny.jpeg",
            nameFull: "索尼老師",
            title2: "弦之戰士🔆索尼老師",
            name: "索尼老師",
            title:["結他類"],
            icon:"⚡️",
            p1:"同行🙌🏻支援",
            p2:"與學生一同在音樂世界冒險修煉，成為學生最強大的後盾，令學生可以安心在音樂世界中成長，也希望學生可以在音樂世界得到一些生活及成長上的啟發。以人為本，因應不同需求，滿足學生的需要❣️",
            p3:"中學時期開始學習結他，喜愛接觸不同音樂風格，特別熱衷於日系搖滾音樂，於2022年奪得港澳區Rockschool音樂等級考試Top Achiever的獎項。\n現為澳門Busking 組合 \"Friday Night\"成員。"
        },
        {
            tid:23,
            img: "eugene.jpeg",
            nameFull: "Eugene",
            title2: "光之巨人🦸🏻‍♂️Eugene",
            name: "Eugene",
            title:["流行鋼琴"],
            icon:"☀️",
            p1:"要令學生成為玩音樂的人！",
            p2:"渾身閃耀着白色的光芒，擁有多種曲風技能：爵士樂、節奏籃調、放克、日系搖滾、後搖滾、數子搖滾等！隨住曲風變化成不同型態，引領學生在學習樂器的同時，也要提升學生的音樂品味🎵",
            p3:"Daze in White成員，負責電結他、作曲。\n從小接觸音樂，現在主要從事作曲、編曲及監製工作。曾為多個歌唱比賽及音樂會擔任現場樂隊樂手，亦有與澳門及香港不同音樂人合作製作音樂的相關工作。\n參與創作的作品曾獲得歌曲金獎、最佳監製、最佳編曲、最佳作曲等多個獎項🏆"
        },
        {
            tid:24,
            img: "sammi.jpeg",
            nameFull: "Sammi老師",
            title2: "人聲救星之忍🥷Sammi老師",
            name: "Sammi老師",
            title:["全方位流行歌唱"],
            icon:"💊",
            p1:"動聽歌聲，開心學習，人聲病夫，多種解藥，能醫百病",
            p2:"專長醫療忍術，技術非常高超，為迷失或受傷的忍者進行治療；在舞台演出時，也顯示了其通靈之術，令聽眾為之着迷。Sammi老師也常與其他忍者村進行交涉活動，舉辦成果展，散發唱歌的愉快氣氛🥰",
            p3:"澳門理工學院藝術高等學校主修音樂教育；美國L.A. music academy主修流行歌唱；考獲英國Rockschool演唱級八級聲樂証書及英國皇家音樂學院八級鋼琴証書。\n擁有多年歌唱教學經驗，並擔任各平台之歌唱培訓班導師及比賽評判等。"
        }
]
