import Home from "./pages/Home";
import  Contact from "./pages/Contact";
import  VideoShare from "./pages/VideoShare";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import MusicCourse from "./pages/MusicCourse";
import MusicCourses from "./pages/MusicCourses";
import ArtCourse from "./pages/ArtCourse";
import ArtCourses from "./pages/ArtCourses";
import SpecialCourse from "./pages/SpecialCourse";
import SpecialCourses from "./pages/SpecialCourses";
import Tutors from "./pages/Tutors";
import Tutor from "./pages/Tutor";
import Enroll from "./pages/Enroll";
import LendingInstrument from "./pages/LendingInstrument";
import LendingPlace from "./pages/LendingPlace";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />}/>
        <Route path="/blogs/blog" element={<Blog />}/>
        <Route path="tutors" element={<Tutors />} />
        <Route path="tutors/tutor" element={<Tutor />}/>
        <Route path="courses/music" element={<MusicCourses />}/>
        <Route path="courses/art" element={<ArtCourses />}/>
        <Route path="courses/special" element={<SpecialCourses />}/>
        <Route path="video" element={<VideoShare />} />
        <Route path="/enroll" element={<Enroll />}/>
        <Route path="courses/music/course" element={<MusicCourse />}/>
        <Route path="courses/art/course" element={<ArtCourse />}/>
        <Route path="courses/special/course" element={<SpecialCourse />}/>
        <Route path="lendinginstrument" element={<LendingInstrument />}/>
        <Route path="lendingplace" element={<LendingPlace />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
