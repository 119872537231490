export const CoursesData = {
    "music":[
        {
            cid:0,
            titleFull:"爵士鼓 Drumset",
            title:"爵士鼓",
            img:"drums.jpg",
            p1:"「手腳很忙的樂器」，一個4歲的小孩坐在鼓椅上這樣形容。",
            p2:["爵士鼓Drumset / Drumkit，相信大家沒有打過都一定有見過，一整套有鼓有Cymbals （是的，金色一片片的是Cymbals，當然要細分每一片都有它們的名字），基本上四肢都要用盡。之所以會這麼忙，其實是源於在19世紀時，舞台演出以及音樂劇場非常受歡迎，而預算有限，敲擊樂手開始想將幾個人的演奏部分，全都由一人身兼幾職，於是開始想將幾種樂器整合，有了現在 Drumset的樣子。",
                "在常見的4拍子樂曲中，Bass drum 大鼓通常落在 1 及 3 拍，其餘的 2 及 4 拍可能是Snare 小鼓，可能是 Cymbals，這樣感覺起來就有跳舞搖擺的感覺，就像踏腳和拍手。",
                "在20世紀，爵士樂在美國New Orleans 新奧爾良掀起一翻熱潮，Drum set 在爵士樂中擔當非常重要的角色，這個樂器也是這樣慢慢發展起來，所以說起來，在音樂史上Drumset可算是後起之輩，但要打得好卻一點也不簡單。"],
            continuingEducation:true,
            age:4,
            exam:"可考取Rockschool 1-8級證書",
            mode:"單對單或一對二"
        },
        {
            cid:1,
            titleFull:"敲擊樂 Percussion",
            title:"敲擊樂",
            img:"percussion.jpg",
            p1:"敲擊樂Percussion是一種以打、搖動、摩擦、刮等方式產生效果的樂器類，收集於世界各地，亦可能是最古老的樂器。其主要分為無音高敲擊樂器（小鼓、大鼓）及有音高敲擊樂器（木琴、定音鼓）兩大類。",
            p2:["當中要說起最令人著迷的馬林巴琴，那我們一定會說這個樂器是來源於大自然的禮物。",
            "馬林巴琴在敲擊樂中很常演奏用到，琴鍵是由一塊一塊的木頭所組成，驟眼望下去就像一部放大了的鋼琴，不同的是我們每次會選擇不同種類的琴棍，去演譯不同的樂曲，有時是兩枝，有時是四枝（有些馬林巴演奏家會甚至會用到六枝或八枝！），該注意的是，通常會使用兩枝或四枝琴棍去完成一首樂曲，手部所需要的速度、力度及平均度非常依賴良好的基本功，和練武功一樣不能偷懶。",
            "而這個樂器雛形在歷史記載是誕生於危地馬拉（即是墨西哥），但也有人相信同時間在非洲和亞洲的鄉村也存在著。在20世紀初期，經過美國的改良後，出現了我們現在眼見的模樣，而在數十年後，日本馬林巴演奏家安倍圭子更發明了5個八度的馬林巴琴，令到琴的音域更廣更低，令到樂曲的演奏更完整。",
            "卡式敲擊類樂器包括：馬林巴琴Marimba、鋼片琴Glockenspiel、小鼓Snare drum、爵士鼓Drumset、非洲鼓Djembe、木箱鼓Cajon、康加鼓Conga、邦哥鼓Bongo、及多種小樂器。"
            ],
            continuingEducation:true,
            age:4,
            exam: '可考取皇家音樂學院ABRAM 1-8級證書',
            mode:"單對單/一對二/多人敲擊樂重奏"
        },
        {
            cid:2,
            titleFull:"手拍類敲擊樂器 Hand Percussion",
            title:"手拍類敲擊樂器",
            img:"hand.jpg",
            p1:"打擊樂器可能是世界上最古老的樂器，當中手鼓更為最具代表性。",
            p2:["用最原始的打擊方式及最自然的節奏感，發揮着獨特的價值。非洲手鼓Djembe起源最早出現可以回溯到公元500年。",
                "而木箱鼓的發源可能是源於古代非洲的一種鼓，近年木箱鼓漸漸在世界各地流行起來，尤其是一些街頭音樂表演團體，由於它可以奏出和爵士鼓相近的音色，而且亦比爵士鼓易於擕帶和使用，因而成為近年來不少敲擊樂手所學習和使用。",
                "被稱為世上最療癒的樂器之一的手碟Handpan，是新興的一種打擊樂器，由兩名瑞士人Felix Rohner和Sabina Schärer於2000年所創。因為它神秘的外表和空靈的音色使Handpan很快流行起來，聲音有如流水一般通透，帶著彷彿天籟似的共鳴尾韻，直搗靈魂，一聽難忘。",
                "卡式手拍類樂器包括：非洲鼓Djembe、木箱鼓Cajon、手碟Handpan、康加鼓Conga、邦哥鼓Bongo"
                ],
            continuingEducation:true,
            age:4,
            exam: null,
            mode:"單對單/一對二/多人重奏班"
        },
        {
            cid:3,
            titleFull:"鋼琴 Piano",
            title:"鋼琴",
            img:"piano.jpg",
            p1:"鋼琴被稱為“樂器之王”。",
            p2:["現代鋼琴的音域寬廣，音色多變，表現力極為出色，不但可以作為獨奏、重奏、合奏及伴奏樂器使用，也是音樂創作和聽覺訓練中必不可少的基礎樂器之一。",
                "鋼琴英文名是Piano，Piano是pianoforte的簡稱，其機械裝置有：鍵盤，擊弦機，琴槌，制音器，琴弦和踏板。現代鋼琴的鍵盤達七個八度，最低音為A；也有再加一個小三度的，最高音為C（88鍵）。鋼琴第一次用於獨奏樂器是在1768年J.C.在英國的一次演出。現代鋼琴因形狀和體積的不同，主要分為立式鋼琴和三角鋼琴。音樂會所用的大三角鋼琴是樂器中的龐然大物，有9英尺長，最重的可達79噸。迄今為止最昂貴的鋼琴是一架1888年生產的斯坦威（steinway）牌三角鋼琴，1980年在紐約以18萬英鎊的高價被拍賣。鋼琴因其獨特的音響，88個琴鍵的全音域，歷來受到作曲家的鍾愛。在流行、搖滾、爵士以及古典等幾乎所有的音樂形式中都扮演了重要角色。"
                ],
            continuingEducation:true,
            age:4,
            exam: "可考取皇家音樂學院ABRAM 1-8級證書",
            mode:"單對單"
        },
        {
            cid:4,
            titleFull:"結他/電結他 Guitar",
            title:"結他/電結他",
            img:"guitar.jpg",
            p1:"結他在流行音樂、搖滾音樂、藍調、民歌中，常被視為主要樂器。",
            p2:["結他Guitar是一種彈撥樂器，通常有六條弦，形狀與提琴相似。在古典音樂的領域裡，結他常以獨奏或扮演著相當程度的陪襯角色。",
                "在中世紀階段，歐洲音樂仍未發展出完整的和聲概念，因此結他展開了漫長的演進之路；結他可概略分為兩大類：其一，是歷史悠久，以木製共鳴箱擴音的木結他。其二，是發明於二十世紀，以拾音器加上揚聲器來擴音的電結他。",
                "木結他常被用於古典音樂、民間音樂及流行音樂的演奏或伴奏。電結他則是在搖滾樂、藍調、爵士樂及流行音樂中，佔有其特殊的地位。而電結他的發明與問世，對西方流行文化及音樂發展也有很重要的影響。然而，隨著電子科技與工藝技術的進步與結合，結他的面貌及內涵，又有了更多的轉變。"
                ],
            continuingEducation: true,
            age: 8,
            exam: "可考取Rockschool 1-8級證書",
            mode: "單對單/一對二"
        },
        {
            cid:5,
            titleFull:"烏克麗麗小結他 Ukulele",
            title:"烏克麗麗小結他",
            img:"ukulele.jpg",
            p1:"烏克麗麗Ukulele，亦稱為夏威夷小吉他、四弦琴，是一種夏威夷的撥弦樂器，歸屬在魯特琴樂器一族，通常有四條弦。",
            p2:["1879年，葡萄牙的專業手工藝人和樂器製作家 ManuelNunes 和 Joao Fernandes 還有 Augustine Dias來到夏威夷群島。同時他們發明和發展了來自他們家鄉本土的琴。夏威夷人初次接觸這種樂器的時候，不但認同這種琴音音色的優美，還對演奏者手指在指板上的快速移動感到驚訝；從那時開始，夏威夷人就把這種琴稱為Ukulele意思是「跳躍的跳蚤」。",
                "無論烏克麗麗的不同尺寸或大小，基本完全一樣的。在彈奏和弦上也可非常快上手，不僅邊彈邊唱樂趣無窮，它的聲音有種天生的快樂氛圍，依照不同的彈奏法，時而明亮時而溫暖，因此不少人認為這是非常具有療癒效果的樂器，在彈奏上可讓人抒發情緒，在聽覺上可讓人覺得溫暖開心。"
                ],
            continuingEducation:true,
            age:6,
            exam: null,
            mode:"單對單/一對二/團體班"
        },
        {
            cid:6,
            titleFull:"電貝斯 Bass guitar",
            title:"電貝斯",
            img:"bass.jpg",
            p1:"電貝斯Bass guitar，簡稱貝斯，又稱低音電結他，是現代搖滾樂器組合的主要樂器之一。",
            p2:["在樂隊中，電貝斯在節奏上扮演相當重要的角色。電貝斯的彈奏方式主要以指彈、Slapping和使用撥片。貝斯的形狀和構造都和電結他相似，但是本體大一點、頸部更長。四弦電貝斯最低音的弦比結他最低音的弦低完全八度。",
                "自1950年之後，電貝斯在流行曲中很大程度上取代了低音提琴，適用於很多不同的風格，包括搖滾樂、重金屬音樂、藍調、爵士樂中的低音部。也可在爵士樂、融合爵士、拉丁音樂、放克音樂和一些搖滾樂風格的歌曲中進行獨奏。",
                "因應現在樂團的需要，電貝斯已經不僅只有基本的4弦，也有5弦和6弦之別，因為搖滾樂的發達，更多弦的電貝斯也因應需求而一一誕生。"
                ],
            continuingEducation: false,
            age: 8,
            exam: "可考取Rockschool 1-8級證書",
            mode:"單對單/一對二"
        },
        {
            cid:7,
            titleFull:"小提琴 Violin",
            title:"小提琴",
            img:"violin.jpg",
            p1:"小提琴Violin，屬於四弦的弓弦樂器，是現代管弦樂團弦樂組中最重要的樂器之一。",
            p2:["小提琴主要的特點在於其輝煌的聲音、高度的演奏技巧和豐富、廣泛的表現力。又被稱作為樂器中的女王或皇后。",
                "小提琴是提琴家族中最小、音高最高的一種，比它體型大的提琴有中提琴、大提琴和低音提琴。談到小提琴的外型，就必然要涉及古提琴原始設計者的藝術觀點和技術方法。現代的數學是技術學的工具，但在文藝復興時期數學是藝術的啟蒙，比例和序列是美學研究的要素，並且為所有的藝術家所採用。",
                "大師級的樂器設計者是經過縝密的構思後，才設計出既美觀而又符合演奏要求的外形，同時還周密地考慮到內外的一致性，以及聲學的諧和性，使製作的提琴不僅外形優美、演奏方便，更重要的是具有動聽而又感人的音色、洪亮而又豐滿的力度、極具穿透力的音量和抑揚頓挫控制如意的品質。",
                "小提琴的學習不容易，尤其是初學時發出的聲音不好聽，學習又比較枯燥，為一大瓶頸。學習小提琴的人需要花數年的時間練習，才能成為好的小提琴手。"
                ],
            continuingEducation: false,
            age: 6,
            exam: "可考取皇家音樂學院ABRAM 1-8級證書",
            mode: "單對單/一對二"
        },
        {
            cid:8,
            titleFull:"古箏 Guzheng",
            title:"古箏",
            img:"guzheng.jpg",
            p1:"古箏，又稱箏、秦箏、漢箏，是中國傳統撥弦樂器，有兩千五百年以上的歷史",
            p2:["古箏的形制為長方形木質音箱，弦架（箏柱）可以自由移動，一弦一音，按五聲音階排列，最早以十二弦箏為最多，唐宋時有弦十三根，後增至十六根、十八弦、二十一弦等，目前最常用的規格為二十一弦。",
                "古箏在春秋時期的秦國已經存在，用於原始的舞樂，是漢民族古老的民族樂器，流行於中國各地，經過千百年的發展，主要形成了客家箏、潮州箏、山東箏、河南箏四大流派。 常用於獨奏、重奏、器樂合奏和歌舞、戲曲、曲藝的伴奏。因音域寬廣，音色優美動聽，演奏技豐富，表現力强，而被稱為“眾樂之王”，亦稱為“東方鋼琴”，是中國獨特的、重要的民族樂器之一。"
                ],
            continuingEducation:true,
            age:4,
            exam: "可考取中國音樂學院或上海音樂學院1-10級證書",
            mode:"單對單/一對二"
        },
        {
            cid:9,
            titleFull:"薩克斯管 Saxophone",
            title:"薩克斯管",
            img:"saxophone.jpg",
            p1:"法國知名作曲家柏遼茲（Hector Berlioz）曾經形容薩克斯管是：「時而莊嚴、平靜，時而夢幻、憂傷；時而 如林間微風般難以察覺，有時卻又如同鐘鳴過後，留於謎樣般的撼動。」",
            p2:["色士風Saxophone，也叫薩克斯風、薩克斯管、薩氏管，是一種木管樂器，但是管體通常是用黃銅製造，使得色士風同時具有銅管類樂器的特性，這也導致了在歸類上令不少人產生疑惑。由於從發聲原理上來看，色士風和同樣使用單簧片的單簧管相同，所以便將它歸類為木管樂器。色士風同時也是一種移調樂器，常見調性為E♭和B♭，也有C調和F調。中音色士風以其煙斗狀的外形為人所熟知。",
                "由於其結構特性，同時具備了木管樂器的細膩和靈活以及銅管樂器的大音量和爆發力，並發展出了介於銅管和木管間的演奏技巧。色士風音域寬廣，相對於其他木管樂器，色士風的音色變化靈活，色士風演奏家因此容易發展出有別於他人的個人音色。總體來說色士風是高靈活性低約束性的樂器，有時也會讓人有過於自我表現而不夠內斂的印象。這也是色士風在古典樂中出現相對較少而多在爵士樂或搖滾樂為人喜愛的原因之一。"
                ],
            continuingEducation:true,
            age:8,
            exam: "可考取皇家音樂學院ABRAM 1-8級證書",
            mode:"單對單/一對二"
        },
        {
            cid:10,
            titleFull:"單簧管 Clarinet",
            title:"單簧管",
            img:"clarinet.jpg",
            p1:"工業革命孕育的可不只是手錶和汽車呢。",
            p2:["黑沉沉的Clarinet（單簧管），在木管家族中可算是擔任一個溫柔的角色，從17世紀末開始，她用了一個半世紀慢慢變成了現在的樣子，期間經過了歐洲的工業革命，工業革命使很多製造方式變得機械化，而這個構造複雜的樂器，狀態亦因此變得越來越穩定以及出色。",
                "Clarinet 主要有德國同法國兩個派別，連樂器的構造分別也有不同。\n在17世紀末，第一個改革這個樂器的就是德國人 Johann Denner，往後形成 Oehler System；\n而在19世紀，最後完成樂器改革的則是法國人 Klosé 以及 Buffet 給合 Andir的技術，而形成了Boehm System。"
                ],
            continuingEducation: false,
            age: 8,
            exam: "可考取皇家音樂學院ABRAM 1-8級證書",
            mode:"單對單/一對二"
        },
        {
            cid:11,
            titleFull:"牧童笛 Recorder",
            title:"牧童笛",
            img:"recorder.jpg",
            p1:"木笛Recorder/Blockflöte，又稱牧童笛，是一種木管樂器，直身長管，以邊稜發聲，是哨笛的一種。",
            p2:["直笛音色優美圓潤，歷史悠久，是巴洛克時代的標準獨奏樂器；因為直笛的入門成本較低，因此在世界各國都當作音樂入門的教學樂器，直笛也是歐洲音樂史上的重要獨奏樂器，保存至今最古老的直笛製於14世紀，更古老的西洋畫上亦有類似的笛，在整個巴洛克時代，直笛由於其音色的完美，技巧的高深，通常被賦予和小提琴類似的獨奏地位。",
                "古典時期後，由於直笛的音量被長笛超越，所以在近現代交響樂隊遭淘汰，不為標準古典音樂的樂器被使用。但20世紀復古風興起，不少當代作曲家為直笛編寫了獨奏曲、奏鳴曲、協奏曲。直笛是歐洲的音樂學院的正規專業，而一般隸屬於巴洛克音樂系，但它的音樂魅力顯然不會局限於巴洛克音樂，現代優秀的直笛演奏家可以用8孔的直笛完美而完整地演奏塔爾蒂尼的《魔鬼的顫音》或其他技巧性曲目。",
                "在港澳以及台灣，直笛是大多數中小學的必修樂器。"
                ],
            continuingEducation: false,
            age: 6,
            exam: null,
            mode:"單對單/一對二/團體班"
        },
        {
            cid:12,
            titleFull:"長號 Trombone",
            title:"長號",
            img:"trombone.jpg",
            p1:"長號Trombone，亦稱伸縮喇叭、伸縮號。",
            p2:["長號是一種銅管樂器。一如所有銅管樂器，長號透過吹奏者以雙唇振動空氣來發聲；而與其他使用按鍵的銅管樂器不同之處在於，長號改變音高的方式通常不是按鍵式的活門，而是以右手前後拉動滑管，是所有管樂器中唯一以此方式演奏的樂器，這使長號與其他銅管樂器不同，能表現出一種較為直接，更偏於自然人聲的音色，這也是許多樂曲中不可或缺的元素之一；而當表現溫暖的音色時，又能帶有另一種不同於其他銅管樂器的味道。",
                "長號有獨特的吹奏方式，其中圓滑奏（legato）是一種音與音之間連間連結的技巧，相對於其它管樂器，圓滑的吹奏對於長號是較難做到的，因為音符與音符常常需要切換把位，而在這過程中時間空隙的產生在所難免；且把位與把位之間會經過泛音列，吹奏者必須避免滑音的產生。長號的特色之處，更常帶來令聽眾驚奇與歡樂的效果，這些技巧常應用於爵士樂中，故長號又有「爵士樂之王」的美譽。"
                ],
            continuingEducation: true,
            age: 8,
            exam: "可考取皇家音樂學院ABRAM 1-8級證書",
            mode: "單對單/一對二"
        },
        {
            cid:13,
            titleFull:"樂理 Theory",
            title:"樂理",
            img:"theory.jpg",
            p1:"樂理，即音樂理論，是研究音樂實踐和可行性的學科。",
            p2:["《牛津音樂指南》給出了術語「樂理」的三個相關用法：第一是「基本原理」，也就是必須瞭解的音樂符號，包括調號、拍號和節奏符號等等。第二是學習從古至今學者對音樂的有關研究。第三是當前音樂學研究的一個子領域，旨在「尋求定義音樂的程序和普遍規範」。它與音樂分析不同，不針對個別的作品和表現，而着重於構成音樂的根本規則。」",
                "樂理通常關注音樂家如何製作音樂，包括如何作曲和編曲。範圍包括旋律、音程、節奏、和聲、結構、曲式、織體等。它源於觀察和涉及關於音樂家和作曲家如何創作音樂的假定推測。懂得樂理有助作曲及音樂演繹。這個詞也形容音樂中的基礎元素，如音高、節奏、和聲和形式且指的是音樂相關的描述、概念或信念的學術研究和分析。因為「何為音樂」的概念正不斷擴大中（請參照音樂的定義），對於樂理的一個更具包容性的定義可能是任何聲波現象的考慮，包括寂靜，因為它也與音樂有關。",
                "在現代學術界，樂理是音樂學的一個子領域，而音樂學本身是藝術和人文學科整體領域內的一個子領域。從詞源上而言，樂理是一種音樂的沉思行為，它代表觀看、查看、沉思、推測、理論，也是一種景象、一種場面。"
                ],
            continuingEducation: true,
            age: 6,
            exam: "可考取皇家音樂學院ABRAM 1-8級證書",
            mode: "單對單/一對二/團體班"
        }]
    ,
    "art":[
        {
            cid:0,
            title:"小朋友繪畫班",
            img:"drawing.jpeg",
            p:`這個時期孩子是展示自我最初性的階段。他們用天性探索、感知去表達對事物的好奇，用圖形把所看到和充滿想像力的世界，揮動手臂畫出塗鴉，通過想像和創造力自由發揮，鼓勵他們大膽表達出來，讓藝術成為表達他們對世界方式。

3-5歲 藝術啟蒙班
成長階段：自我感知及象徵表達
            
課程理念：
通過不同主題讓孩子感受色彩、形狀、質感，誘發他們的興趣、啟發思維創意。多種繪畫材料及工具應用，讓孩子體驗不同的表現形式；增強手部協調性，鍛煉孩子對色彩感知能力、思維創新能力、不斷提高孩子學習興趣和自信心，引導孩子增加知識面及觀察，打開自信的大門。讓孩子有創意、懂表達會思考，激發存在的藝術潛能。
            
課程內容：
探索、故事、遊戲、手工、感受、認知體驗、自由創作

課堂使用工具包括：水粉顏料、彩鉛、油粉彩、輕黏土、麥克筆等等⋯

—————————————————————————

5-9歲
藝術創思班
成長階段：象徵表達及意向表達

課程理念：
針對孩子從美術啟蒙向獨立創作的過渡階段的美術基礎課程。通過藝術啟發思維，激發創意繪畫表現能力、引導將藝術融入生活，提升色彩和造型能力的基礎，循序漸進鼓勵給孩子有豐富藝術想像力和創作思維，提高審美能力，學會如何表達的溝通，讓孩子有善於發現的眼睛和創新的思維能力。

課程目標：
材料實踐、多種藝術引導、構圖、觀察技巧、色彩美感、造型訓練、故事性表達

課程內容：
藝術概念基礎、造型媒材應用、色彩感培養

課堂使用工具包括：
丙烯顏料、油畫顏料、彩鉛、油粉彩、水彩、麥克筆、輕黏土等等⋯

—————————————————————————
9-13歲
造型創意班

成長階段：造形創意與審美敏感

課程內容：
造型空間思維應用技巧、色感概念應用、藝術概念知識基礎延伸、繪畫立體藝術鑒賞

課程理念：
利用造型訓練方法，加強學生對造型結構創新的訓練，激發學生積極想像和邏輯思維協調形式，引導學生去表現色彩與形體的多樣性，培養更細緻觀察和豐富的想像力，激勵學生自由創作的自發性和積極性，培養學生的興趣，學會獨立思考表現能力，懂得利用不同媒材的運用去表達，發揮學生獨有的潛能，往專業的特長前進。`

        },
        {
            cid:1,
            title:"成人油畫班",
            img:"d2.jpeg",
            p:`油畫的前身15世紀歐洲的蛋彩畫。油畫逐漸代替了蛋彩畫。油畫是將顏料溶解在植物油裡，蛋彩畫是將顏料融進蛋清

傳統油畫以亞麻子油調和顏料稱為油彩繪制。在經過處理的布或板上作畫，因為油畫顏料乾後不變色，多種顏色調和不會變得骯髒，畫家可以畫出豐富、逼真的色彩。油畫顏料不透明，覆蓋力強，所以繪畫時可以由深到淺，逐層覆蓋，使繪畫產生立體感。油畫適合創作大型、史詩般的巨作。成為西方繪畫史中的主體繪畫方式，現在存世的西方繪畫作品主要是油畫作品。絕大部分壁畫作品也是用油畫顏料和創作方式製作的。19世紀後期，由於科技發展，許多新材料應用於油畫領域，如丙烯顏料或油漆而不局限於狹義的油彩等。`
        },
        {
            cid:2,
            title:"禪繞畫",
            img:"d3.jpeg",
            p:`繪製方法：

禪繞畫的繪製輕鬆有趣、簡單易學。無須深厚的繪畫基礎，也無須特定的場地，一支黑筆、一支鉛筆、一張紙，就可以隨時隨地盡情纏繞。盤曲纏繞的線條構成了禪繞畫的基本紋樣，多種紋樣的自由組合則構成了一幅完整的禪繞畫。

它不僅僅是一種藝術創作，不少人還利用它來舒緩壓力，所以還被稱為“頭腦瑜伽”，正是因為通過纏繞，人腦可以很容易的進入到冥想狀態，逐漸達到深度的專注。在以精緻的線條重複畫圖形時，每個人都可以專注於每一個筆畫，在這樣的意識狀態下，心智、直覺和知識都能迅速、精確且不費吹灰之力地共同運轉。

藝術特點：

禪繞畫被譽為來自美國的心靈藝術，並風靡歐美國家，是一種全新的繪畫方式，在設定好的空間內用不斷重複的基本圖形來創作出美麗圖案。畫出來的圖案，有點像“秘密花園”填色書上圖案的模樣 [1] 。
禪繞的世界裏，沒有比較，沒有對錯，只要跟着感覺畫，自在愉悦就好，所以又被稱為“心靈的畫作”。
禪繞畫的素材取之自然、取之生活，再加上人的無限想象力，可以無限的延伸，產生出各種各樣的可能性。`
        },
        {
            cid:3,
            title:"手作類",
            img:"h2.jpg",
            p:`手作是使用布、紙、竹條、金屬等材料，運用縫紉、摺疊、編織、金屬纏繞⋯等等技術，由人親手完成的作品。

起源
手作始於原始社會，人類為求生存而與自然環境有所互動，也因此產生了多樣需求。眾人便創造出各種物品來維繫日常生活所用，使得手工藝製作成為人類的主要活動之一。`
        },
        {
            cid:4,
            title:"花藝",
            img:"flower.jpg",
            p:`花藝是人們表現自然的生命、展示自然的魅力以及人的内心世界對自然、人生、藝術和社會生活體悟的媒介，是人们借助於自然界的花草作为修身養性、陶冶情操、美化生活的一種方式。

卡式藝術特約的花藝課程，根據不同的季節與節日，搭配不同嘅花材及節日素材，不定期舉辦不同的花藝課程，令生活加添一點小美好。`
        },
        {
            cid:5,
            title:"DIY香水課程系列",
            img:"s.jpeg",
            p:`課程介紹:
簡單認識香水的知識
了解香型分類
設計香味主調
設計及製作獨一無二的尊屬香水

1:《DIY香水製作》
成品：尊屬香水一支
製作時間：約2小時

2:《DIY無火水晶香薰擴香套裝》
成品：自選水晶配搭香薰杯一個
自調香薰精油10ml 一支
製作時間：約2小時

課程資訊:
適合學習年齡：成人或有需要人士
上課模式：團體班`
        }
    ],
    "special":[
        {
            cid:0,
            title:"流行唱歌技巧",
            img:"c.jpg",
            p:`歌唱，或唱歌，是指人類透過發聲器官產生音樂的過程。進行歌唱的人、或是以歌唱為職業的人稱為歌手。歌唱可以作為在正式場合的一種表演藝術、可以是有宗教、教育、營利等目的、也可以是日常生活的娛樂；歌唱可以是經過準備及練習的，也可以是即興的。如同其它技術，純熟的歌唱技巧需要經過持續且充份的練習。

歌唱對身體有正面的幫助，可以增加肺活量、降低憂鬱情緒、減輕壓力、增加免疫能力，亦可以帶給人信心、愉悅、滿足、情感。`,
            p2:`此課程為澳門歌手陳慧敏及程文政主理
適合學習年齡：12歲或以上
上課模式：單對單或團體班`
        },
        {
            cid:1,
            title:"填詞",
            img:"s2.jpeg",
            p:`//分隔著十萬八億河流水面
想抱著你黑夜觀天
每夜在尋幸福相遇見
若細雨也要舞翩翩//

有冇曾經有一首歌嘅詞令你感動？
有冇曾經有一首歌嘅詞令你振奮？

觸動人心嘅歌詞，同曲一樣都係歌曲嘅靈魂，認識及應用填詞嘅原則及技巧，我手寫我心，學習創作屬於自己嘅作品。`,
            p2:`流行曲基礎填詞班
導師：此課程為澳門歌手及填詞人程文政主理
適合學習年齡：12歲或以上
上課模式：團體班`
        },
        {
            cid:2,
            title:"小小口才訓練班",
            img:"t.jpeg",
            p:`啟發小朋友的自我表達能力，以歡快的形式使小朋友投入其中。從中學習發音、咬字、動作、表情以及肢體技巧。使小朋友開拓自信、勇於在眾人面前講話。亦讓小朋友認識舞台技巧，增強即興力訓練，克服一切舞台緊張感。`,
            p2:`小小口才訓練班
導師：此課程為澳門司儀程文政主理
適合學習年齡：7-12歲
上課模式：團體班`
        },
        {
            cid:3,
            title:"成人司儀演講口才班",
            img:"c.jpg",
            p:`一言以興邦，一言以喪邦。一句話能讓人笑，一句話能讓人跳，可見口才的重要性。語氣輕重的運用，言語的合理停頓，使口才比文才更有感染力。

口才是一个人智慧的反映，是影響一個人事業成功、人際和睦、生活幸福的重要因素，是一種可隨身攜帶永不過時的基本能力，職場商務談判，銷售溝通，介紹項目，求職面試等等，都會運用到口才。`,
            p2:`成人司儀演講口才班
導師：此課程為澳門司儀程文政主理
適合學習年齡：成人或有需要人士
上課模式：團體班`
        },
    ]
}
