import React, { useEffect, useState } from 'react';

function Footer() {
  return(
    <footer class="bg-grey">
        <div class="mx-auto w-full px-10 py-6 lg:py-8">
            <div class="md:flex md:justify-between">
              <div class="mb-6 md:mb-0">
                  <a href="/" className="font-bold text-2xl flex items-center">
                      Cadenza卡式藝術
                  </a>
              </div>
              <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                  <div>
                      <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-black">關於我們</h2>
                      <ul class="text-black font-medium">
                          <li class="mb-4">
                              <a href="/tutors" class="hover:underline">導師資訊</a>
                          </li>
                          <li class="mb-4">
                              <a href="/video" class="hover:underline">影片分享</a>
                          </li>
                          <li class="mb-4">
                              <a href="/blogs" class="hover:underline">活動及資訊</a>
                          </li>
                          <li>
                              <a href="/contact" class="hover:underline">聯絡我們</a>
                          </li>
                      </ul>
                  </div>
                  <div>
                      <h2 class="mb-6 text-sm font-semibold text-black uppercase">課程資訊</h2>
                      <ul class="text-black font-medium">
                          <li className="mb-4">
                              <a href="courses/music" class="hover:underline ">音樂課程</a>
                          </li>
                          <li className="mb-4">
                              <a href="courses/art" class="hover:underline">藝術課程</a>
                          </li>
                          <li>
                              <a href="courses/special" class="hover:underline">特別課程</a>
                          </li>
                      </ul>
                  </div>
                  <div>
                      <h2 class="mb-6 text-sm font-semibold text-black uppercase">服務</h2>
                      <ul class="text-black font-medium">
                          <li class="mb-4">
                              <a href="/lendinginstrument" class="hover:underline">
                                樂器租借
                              </a>
                          </li>
                          <li class="mb-4">
                              <a href="/lendingplace" class="hover:underline">
                                場地租借
                              </a>
                          </li>
                          <li>
                              <a href="/enroll" class="hover:underline">立即報名</a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
          <hr class="my-6 sm:mx-auto border-black lg:my-8" />
          <div class="sm:flex sm:items-center sm:justify-between">
              <span class="text-sm sm:text-center text-black">This Webiste is created by Eric Un © Bricks Academy. All Rights Reserved.
              </span>
              <div class="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
                  <a href="https://www.facebook.com/Cadenza.edu.mo" class="text-black">
                      <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                            <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                        </svg>
                      <span class="sr-only">Facebook page</span>
                  </a>
                  <a href="https://www.instagram.com/cadenza_mo/" class="text-black">
                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                    </svg>
                      <span class="sr-only">Instagram page</span>
                  </a>
              </div>
          </div>
        </div>
    </footer>)
}



export default Footer;
