import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderWhite from "../components/SubHeaderWhite"
import Footer from "../components/Footer";
function Contact(){
    return (
        <>
            <Header></Header>
            <SubHeaderWhite title={"聯絡我們"}></SubHeaderWhite>
            <section>
                <div className="p-10 flex flex-col justify-around xl:flex-row xl:p-28">
                    <div className="w-[80vw] xl:w-[30vw]">
                        <h1 className='title text-main  mb-[3vh] mt-[3vh]'>📍地址</h1>
                        <p className="mb-[3vh]">澳門宋玉生廣場258號建興龍廣場22樓N及5樓I</p>
                        <hr />
                        <div>
                            <h1 className='title text-main  mb-[3vh] mt-[3vh]'>營業時間</h1>
                            <div className="flex flex-col mb-[3vh]">
                                <p className="mb-1">星期一: 12:00 - 20:00</p>
                                <p className="mb-1">星期二: 12:00 - 20:00</p>
                                <p className="mb-1">星期三: 12:00 - 20:00</p>
                                <p className="mb-1">星期四: 12:00 - 20:00</p>
                                <p className="mb-1">星期五: 12:00 - 20:00</p>
                                <p className="mb-1">星期六: 10:00 - 18:00</p>
                                <p className="mb-1">星期日: 10:00 - 18:00</p>
                            </div>
                        <div/>
                        <hr />
                        <div className="flex flex-col mb-[3vh] mt-[3vh]">
                            <div className="mb-1"><strong>📧Email: </strong> <a className="text-main" tabindex="0" role="link" href="mailto:cadenza.edu.mo@gmail.com" target="_blank" rel="noopener">cadenza.edu.mo@gmail.com</a></div>
                            <div className="mb-1"><strong>📞電話:</strong> (853) 2832 3729</div>
                            <div className="mb-1"><strong>Facebook: </strong><a className="text-main" href="https://www.facebook.com/Cadenza.edu.mo">Cadenza卡式藝術教育中心</a></div>
                            <div className="mb-1"><strong>Instagram: </strong><a className="text-main" href="https://www.instagram.com/cadenza_mo/?hl=en">cadenza_mo</a></div>
                            <div className="mb-1"><strong>💬wechat: </strong> cadenza_macau</div>
                        </div>

                        </div>
                    </div>
                    <iframe className="w-[80vw] h-88 xl:w-[40vw] xl:h-128" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3694.338012234803!2d113.54875171058332!3d22.189257179682215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34017ac2574ce195%3A0xd62ffabc998dbc0c!2z5bu66IiI6b6N5buj5aC0MjM4LTI4NiBBbGFtZWRhIERyLiBDYXJsb3MgZCdBc3N1bXBjYW8sIE1hY2Fv!5e0!3m2!1sen!2s!4v1689141129709!5m2!1sen!2s"  style={{"border":"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
            <Footer></Footer>
        </>
    )

}

export default Contact;