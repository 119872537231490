import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import SubHeaderBlack from "../components/SubHeaderBlack"
import Footer from "../components/Footer";
import { useSearchParams } from 'react-router-dom';
import {TutorsData} from "../data/TutorsData";
function Tutor(){
    const [searchParams, setSearchParams] = useSearchParams({tid:'-1'});
    const tid = searchParams.get('tid');
    const t = TutorsData[tid - 1];
    return(
        <>
            <Header></Header>
            <SubHeaderBlack title={"導師"}></SubHeaderBlack>
            {t?<section className="px-4 py-16 mx-auto lg:max-w-5xl md:px-8 lg:px-8 lg:py-20">
                <div className="flex flex-col md:flex-row mb-10">
                    <div className="w-[100%] md:w-[50%]">
                        <img style={{"object-fit": "cover", "object-position":"95% 0"}} className="rounded w-full h-128" src={process.env.PUBLIC_URL +'/'+ t.img}></img>
                    </div>
                    <div className="w-[100%] md:w-[50%] md:ml-20">
                        <h1 className='text-4xl text-main  mb-4 mt-4 font-bold'>{t.icon}{t.nameFull}</h1>
                        <h2 className='text-2xl text-main  mb-4 mt-4 font-bold'>{t.title2}</h2>
                        <p className="mb-3 text-xs lg:text-sm text-black">
                            {t.title.map((ti)=>{
                                return(
                                    <span className="mr-3 px-5 py-2 rounded-lg bg-grey inline-block mb-3">
                                        {ti}
                                    </span>
                                )
                            })}
                        </p>
                        <p className="my-4 text-lg">
                            {t.p1}
                        </p>
                        <hr></hr>
                        {t.p2?<><h1 className='text-4xl text-main  mb-4 mt-4'>能力</h1>
                        <p>
                            {t.p2}
                        </p></>:""}
                    </div>
                </div>
                <hr></hr>
                <div className="my-4">
                    <h1 className='text-4xl text-main  mb-4 mt-4'>{t.icon}個人簡介</h1>
                    <p>
                        {t.p3}
                    </p>
                </div>
            </section>:""}
            <Footer></Footer>   
        </>
    )
}

export default Tutor;