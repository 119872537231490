import Header from "../components/Header";
import SubHeaderWhite from "../components/SubHeaderWhite"
import Footer from "../components/Footer";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


function LendingInstrument(){
    const form = useRef();
    const [submited, setSubmited] = useState('false')
    const sendEmail = (e) => {
        setSubmited('正在處理');
        e.preventDefault();
  
        emailjs.sendForm('service_l7wla2g', 'template_m3i6nca', form.current, 'ilrxhwyBLs3LINpWt')
            .then((result) => {
                setSubmited('true');
            }, (error) => {
                setSubmited('未知錯誤發生')
        });
    };

    return (
        <>
            <Header></Header>
            <SubHeaderWhite title={"樂器租借"}></SubHeaderWhite>
            
            {submited==='false'?<section className="p-12 flex" >
                    <form className="mx-auto p-4 lg:p-10 bg-gray-50 w-full max-w-3xl rounded-lg" ref={form} onSubmit={sendEmail}>
                        <h1 className='mx-3 mb-6 px-3 text-main lg:text-6xl text-5xl'>樂器租借</h1>
                        <div class="mx-3 mb-6 px-3">
                                <label class="block uppercase tracking-wide text-gray-800 text-lg font-bold mb-2" for="grid-last-name">
                                    名字
                                </label>
                            <input name="name" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  type="text"></input>
                        </div>
                        <div class="mx-3 mb-6 px-3">
                                <label class="block uppercase tracking-wide text-gray-800 text-lg font-bold mb-2" for="grid-last-name">
                                    Email
                                </label>
                            <input name="email" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  type="text"></input>
                        </div>
                        <div class="mx-3 mb-6 px-3">
                                <label class="block uppercase tracking-wide text-gray-800 text-lg font-bold mb-2" for="grid-last-name">
                                    電話
                                </label>
                            <input name="phone_number" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  type="tel"></input>
                        </div>
                        <div class="mx-3 mb-6 px-3">
                                <label class="block uppercase tracking-wide text-gray-800 text-lg font-bold mb-2" for="grid-last-name">
                                    WechatID
                                </label>
                            <input name="wechat_ID" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  type="text"></input>
                        </div>

                        <div class="mx-3 mb-6 px-3">
                            <div class="w-full mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-800 text-lg font-bold mb-2" for="grid-state">
                                    想要租借的樂器
                                </label>
                                <div class="relative">
                                    <select name="instrument" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                        <option>爵士鼓 Drumset</option>
                                        <option>馬林巴木琴 Marimba</option>
                                        <option>鋼片琴 Glockenspiel</option>
                                        <option>小鼓 Snare drum</option>
                                        <option>非洲鼓 Djembe</option>
                                        <option>木箱鼓 Cajon</option>
                                        <option>康加鼓 Conga</option>
                                        <option>邦哥鼓 Bongo</option>
                                        <option>電子琴 Electronic piano</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-3 mb-6 px-3">
                                <label class="block uppercase tracking-wide text-gray-800 text-lg font-bold mb-2" for="grid-last-name">
                                    想要租借的時間
                                </label>
                            <input name="time" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  type="text"></input>
                        </div>
                        <div class="mx-3 mb-6 px-3">
                            <input type="submit" value="Submit" className="p-3 bg-gray-300 rounded-lg cursor-pointer" />
                        </div>
                    </form>
            </section>:submited === 'true'?<h1 className="p-20 text-main text-2xl">已經收到你的報名</h1>:<h1 className="p-20 text-main text-2xl">{submited}</h1>}
            <Footer></Footer>
        </>
    )

}

export default LendingInstrument;