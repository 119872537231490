import React, { useEffect, useState } from 'react';
import Sketch from "react-p5";
let eyeBall;
let cat;
let eyeBallPosX;
let eyeBallPosY;
let size;
let size2;
function SubHeaderBlack({title}){
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(document.getElementById("p5-subHeader").offsetWidth, document.getElementById("p5-subHeader").offsetHeight).parent(canvasParentRef);
    cat = p5.loadImage(process.env.PUBLIC_URL + "/blackCatNoEyesBall.png");
    eyeBall = 
      {
        PosX: -31,
        PosY: 0,
  
      }
  };

  function draw(p5) 
  {
    p5.background('#3985A5');
    p5.push()
    p5.translate(p5.width - 270, p5.height - 300)
    p5.image(cat, 0, 0, 300, 390)
    p5.pop()
    size = 13
    size2 = 3
    p5.translate(p5.width - 270, p5.height - 300)
    if( p5.map(p5.mouseY, 0, p5.height * 2 - 126, -6, 6) > 5){
      eyeBallPosY = 5
    }else{
      eyeBallPosY = p5.map(p5.mouseY, 0, p5.height * 2 - 126, -6, 6)
    }
    eyeBallPosX = p5.map(p5.mouseX, 0, p5.width * 2 + 100, -6, 6) 
  
    
    if(p5.mouseIsPressed) 
    {
      size = 3
      size2 = 0
    }
    
    p5.push()
    p5.fill(50)
    p5.translate(-eyeBall.PosX, eyeBall.PosY)
    p5.ellipse(eyeBallPosX + 152, eyeBallPosY + 139, 10, size)
    p5.pop()
    
    
    p5.push()
    p5.fill(50)
    p5.translate(eyeBall.PosX, eyeBall.PosY)
    p5.ellipse(eyeBallPosX + 150, eyeBallPosY + 130, 10, size)
    p5.pop()
    
    p5.push()
    p5.fill(0)
    p5.translate(-eyeBall.PosX, eyeBall.PosY)
    p5.ellipse(eyeBallPosX + 152, eyeBallPosY + 139, 3, size * 0.6)
    p5.pop()
    
    
    p5.push()
    p5.fill(0)
    p5.translate(eyeBall.PosX, eyeBall.PosY)
    p5.ellipse(eyeBallPosX + 150, eyeBallPosY + 130, 3, size * 0.6)
    p5.pop()

    p5.push()
    p5.fill(255)
    p5.translate(-eyeBall.PosX, eyeBall.PosY)
    p5.ellipse(eyeBallPosX + 150, eyeBallPosY + 136, size2, size2)
    p5.pop()
    
    
    p5.push()
    p5.fill(255)
    p5.translate(eyeBall.PosX, eyeBall.PosY)
    p5.ellipse(eyeBallPosX + 148, eyeBallPosY + 127, size2, size2)
    p5.pop()
  }

  function windowResized(p5) {
    p5.resizeCanvas(document.getElementById("p5-subHeader").offsetWidth, document.getElementById("p5-subHeader").offsetHeight);
  }

  return(
    <>
      <div id="p5-subHeader" className="w-[100%] relative" style={{"height":"70vh"}}>
            <Sketch setup={setup} draw={draw} windowResized={windowResized}/>
            <div style={{"left":"10%", "top":"40%"}}className="absolute w-[80vw]">
                <h1 className="font-extrabold headerTitle" style={{"color":"white"}}>
                  {title}
                </h1>
            </div>
        </div>
    </>
  )
}

export default SubHeaderBlack